export enum DashboardConstants {
  DashboardByEmail = "DashboardByEmail",
  Dashboard = "Dashboard",
}

export const DashboardItemCardColor = [
  "linear-gradient(to left top, #5b4f96, #574d97, #524b98, #4d499a, #48479b, #454fa2, #4156a8, #3e5eae, #4170b9, #4a82c2, #5893ca, #6aa4d1)",
  "linear-gradient(to right top, #63d363, #4cd177, #34cf89, #1acc99, #00c9a7)",
  "linear-gradient(to right, #63d38a, #00c1c0, #00a6d6, #7386be, #906a8d)",
  " radial-gradient(circle, #563b81, #563e8d, #55429a, #5146a7, #4b4bb5)",
  "linear-gradient(to left, #a693c4, #78a5df, #04b7e4, #00c5ca, #3ccd9a)",
  "",
  "",
];
