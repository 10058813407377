import {
  DateCalendar,
  DateView,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";

const CustomDatepicker: React.FC<{
  onSelect: (date: Date) => void;
  minDate?: Date;
  selectedDate: Date | null;
  disablePast?: boolean;
  views?: "day" | "month" | "year";
  maxDate?: Date | undefined;
}> = ({
  minDate = new Date(),
  onSelect,
  disablePast = true,
  views = "day",
  selectedDate = null,
  maxDate = undefined,
}) => {
  //const [date, setDate] = React.useState<Date | null>(new Date());
  const onDateChange = (date: Date | null) => {
    if (!date) return;
    onSelect(date);
    // setDate(date);
  };
  return (
    <div>
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateCalendar
            maxDate={maxDate}
            minDate={minDate}
            disablePast={disablePast}
            views={[views as DateView]}
            value={selectedDate}
            // shouldDisableDate={disableDateHandler}
            onChange={onDateChange}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default CustomDatepicker;
