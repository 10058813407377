import { Button, Divider, Paper } from "@mui/material";
import React, { useState, FormEvent, useEffect } from "react";
import styles from "./style.module.css";
import { useNavigate } from "react-router";
import { InputField } from "ui/inputs";
import { Body, ErrorMessage, Header } from "ui/typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  ErrorType,
  LoginVerificationForm,
  OptVerificationType,
} from "@hubix/shared";
import { CustomLink, CustomLinkWithQuestion } from "ui/link";
import { LoginThunk } from "../store";
import { setStateErrorToNull } from "utils/store";
import PhoneNumberInput from "ui/phonenNumber";

const LoginForm: React.FC = () => {
  const [loginForm, setLoginForm] = useState<LoginVerificationForm>(Object);

  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  const clientAuth = useAppSelector((state) => state.auth);
  const optError = useAppSelector((state) => state.error);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const resetError = () => {
    if (!showError) return;
    dispatch(setStateErrorToNull());
    setShowError(false);
  };

  const handlePhoneNumber = (phoneNumber: string) => {
    resetError();
    setLoginForm({
      ...loginForm,
      phoneNumber,
      verificationType: OptVerificationType.SMS,
    });
  };

  useEffect(() => {
    if (clientAuth) {
      if (clientAuth.isOptSent) {
        return navigate(`/verification/${clientAuth.client?._id}`);
      }
    }
    if (optError) {
      if (optError.type === ErrorType.NoUserFound) {
        setError("Account provided does not exist");
        setShowError(true);
      }
      if (optError.type === ErrorType.LoginFailed) {
        setError("Something wen wrong with the server ");
        setShowError(true);
      }
    }
  }, [clientAuth, optError]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    resetError();

    dispatch(LoginThunk(loginForm));
  };

  return (
    <div style={{ height: "100%", margin: "auto", marginTop: "80px" }}>
      <Paper elevation={3} className={styles.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: 20,
              height: "80%",
              margin: "auto",
            }}
          >
            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "30px",
              }}
            >
              <Header text={"OTP verification"} />
              <Divider style={{ height: 20, minHeight: 20, width: "0px" }} />

              <Body text={"Request a one time OPT Login code"} />
            </div>

            <Divider style={{ height: 70, minHeight: 40, width: "0px" }} />
            <div style={{ display: "flex", gap: 8, justifyContent: "center" }}>
              <ErrorMessage text={error} showError={showError} />
              {showError && optError?.type === ErrorType.NoUserFound && (
                <div style={{ marginTop: 5 }}>
                  <CustomLink text={"Sign up"} to={"/signup"} />
                </div>
              )}
            </div>
            <Divider style={{ height: 20, minHeight: 20, width: "0px" }} />

            <form onSubmit={handleSubmit} className={styles.formContainer}>
              <InputField
                value={loginForm.email}
                label="Enter email"
                type="email"
                placeholder="example@domain.com"
                onChange={(email) => {
                  resetError();
                  setLoginForm({
                    email,
                    phoneNumber: "",
                    verificationType: OptVerificationType.Email,
                  });
                }}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Divider style={{ margin: 20 }} />
                <div style={{ margin: "0 10px" }}>Or </div>
                <Divider style={{ maxWidth: "40%" }} />
              </div>

              <div>
                <div>
                  <PhoneNumberInput inputChange={handlePhoneNumber} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: 5,
                  }}
                >
                  <CustomLinkWithQuestion
                    question={"Don't have account ?"}
                    text={"Sign up"}
                    to={"/signup"}
                  />
                </div>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={styles.submitButton}
              >
                Send Opt code
              </Button>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "-15px",
                  gap: "i",
                }}
              ></div>
            </form>
          </div>

          <div
            style={{ width: "100%", color: "white" }}
            className={styles.loginSide}
          >
            <div
              style={{
                margin: "auto",
                width: "fit-content",
                height: "100%",
                padding: "20px",
              }}
            >
              <Header text={"Login verification"} color="white" />
              <Divider style={{ margin: "20px" }} />
              <Body
                text={
                  "Login with a one time OPT code  that will be sent to you with your previously verified contact"
                }
                color="white"
              />

              <Divider style={{ margin: "20px" }} />

              <Body
                text={
                  "Choose your preferred verified contact to recieve  the OPT"
                }
                color="white"
              />
              <div style={{ display: "flex", gap: 10 }}>
                <CheckCircleIcon style={{ color: "rgb(56, 211, 162)" }} />
                Email
              </div>
              <div style={{ display: "flex", gap: 10 }}>
                <CheckCircleIcon style={{ color: "rgb(56, 211, 162)" }} />
                Phone (SMS)
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default LoginForm;
