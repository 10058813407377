export enum ClientConstant {
  Client = "Client",
  Auth = "Auth",
  ConfirmOTP = "ConfirmOTP",
  ClientAuth = "ClientAuth",
  ClientOPTByEmail = "ClientOPTByEmail",
  ClientOPTBySMS = "ClientOPTBySMS",
  ClientSignup = "ClientSignup",
  ClientByEmail = "ClientByEmail",
  ClientOPTById = "ClientOPTById",
  AuthClientByCookie = "AuthClientByCookie",
}
