import React from "react";
import styles from "./footer.module.css";
import { Grid } from "@mui/material";
import { HeightsAndWidth } from "utils/constants";

const Footer: React.FC = () => {
  return (
    <footer
      className={styles.footer}
      style={{ height: `${HeightsAndWidth.FooterHeight}px ` }}
    >
      <Grid>
        <Grid></Grid>
        <Grid></Grid>
        <Grid></Grid>
      </Grid>
      <p>&copy; {new Date().getFullYear()} Your Website</p>
    </footer>
  );
};

export default Footer;
