import SideMenu from "./sideMenu";
import MeetingDashboard from "./meeting";
import { useEffect, useState } from "react";
import Calendar from "./calendar";
import DashboardHome from "./home";
import { Client, DashboardItem, ErrorType } from "@hubix/shared";
import { BodyHeader } from "ui/typography";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { clientAuthByCookieThunk } from "component/client/store";
import IsLoading from "ui/isLoading";
import { Divider } from "@mui/material";
import { getDashboardByClientIdThunk } from "./store";

// TIPS https://dribbble.com/shots/9914282/attachments/1948337?mode=media

const Dashboard: React.FC = () => {
  const [menuItem, setMenuItem] = useState(DashboardItem.DashboardHome);
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client);
  const clientError = useAppSelector(
    (state) => state.error,
    (error) => error?.type !== ErrorType.CookiesAuthFailed
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(clientAuthByCookieThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clientError) {
      if (clientError.type === ErrorType.CookiesAuthFailed) {
        navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientError]);

  useEffect(() => {
    if (!client) return;
    dispatch(
      getDashboardByClientIdThunk({
        type: DashboardItem.DashboardHome,
        email: client?.email,
        clientId: client?._id,
      })
    );

    if (location.pathname.includes("meeting")) {
      setMenuItem(DashboardItem.Meeting);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const DashboardItemMenu = () => {
    return (
      <div>
        {menuItem === DashboardItem.Meeting && (
          <div>
            <MeetingDashboard client={client as Client} />
          </div>
        )}
        {menuItem === DashboardItem.Calendar && (
          <div>Calendar Content Goes Here</div>
        )}
        {menuItem === DashboardItem.Project && (
          <div>Project Content Goes Here</div>
        )}
        {menuItem === DashboardItem.DashboardHome && (
          <div>
            <DashboardHome
              meetings={[]}
              client={Object() as Client}
              menuItem={menuItem}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          //   backgroundColor: "rgb(168, 136, 199)",
          // paddingTop: HeightsAndWidth.NavBarHeight,
          display: client ? "block" : "none",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "20%",
              maxWidth: 200,
              minWidth: 100,
              marginTop: 30,
            }}
          >
            {" "}
            <SideMenu menuItem={menuItem} setMenuItem={setMenuItem} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: "90%",
              width: "90%",
              backgroundColor: "white",
              height: "100vh",
              borderTopLeftRadius: 30,
              borderBottomLeftRadius: 30,
            }}
          >
            <div style={{ width: "70%", marginLeft: 5 }}>
              <div style={{ textAlign: "center", padding: 10 }}>
                <Divider />
              </div>
              <div style={{ width: "100%" }}>
                <DashboardItemMenu />
              </div>
            </div>
            <div style={{ width: "30%", marginTop: 40 }}>
              <Calendar meetings={[]} client={Object()} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: client ? "none" : "block" }}>
        <IsLoading />
      </div>
    </>
  );
};

export default Dashboard;
