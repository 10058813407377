import React, { useRef, useEffect, useState } from "react";
import intlTelInput, { Plugin } from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { handleNumberInput } from "component/client/utils";
import { ErrorMessage } from "./typography";

const PhoneNumberInput: React.FC<{
  inputChange: (phoneNumber: string) => void;
  isRequired?: boolean;
}> = ({ inputChange, isRequired = false }) => {
  const [dialCode, setDialCode] = useState("");
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  const inputRef = useRef<any>();

  const intInput = (): Plugin | null => {
    const iti = intlTelInput(inputRef.current, {
      initialCountry: "auto",
      separateDialCode: true,
      geoIpLookup: function (callback: any) {
        fetch("https://ipapi.co/json")
          .then(function (res) {
            return res.json();
          })
          .then(function (data) {
            callback(data.country_code);
          })
          .catch(function () {
            callback("no");
          });
      },
    });
    return iti;
  };

  const handleInputChange = (phoneNumber: string) => {
    if (phoneNumber.length > 8) {
      setError("Phone number must be 8 digits");
      setShowError(true);
      return;
    }
    if (showError) {
      setShowError(false);
      setError("");
    }

    inputChange(dialCode + phoneNumber);
    setValue(phoneNumber);
  };
  // Initialize the intlTelInput plugin
  useEffect(() => {
    const iti = intInput();
    inputRef.current.addEventListener("countrychange", (e: any) => {
      if (iti) {
        setDialCode(iti.getSelectedCountryData().dialCode);
      }
    });

    return () => {
      if (iti) {
        iti.destroy();
      }
    };
  }, []);

  return (
    <div>
      <input
        ref={inputRef}
        type="tel"
        required={isRequired}
        value={value}
        onChange={(e) => handleInputChange(handleNumberInput(e.target.value))}
        placeholder="67689466"
      />
      <div style={{ display: "flex", gap: 8, justifyContent: "center" }}>
        <ErrorMessage text={error} showError={showError} />
      </div>
    </div>
  );
};

export default PhoneNumberInput;
