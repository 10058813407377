import {
  API_PATH,
  Client,
  ClientSignUpform,
  ClientVerification,
  LoginVerificationForm,
  OTPConfirmation,
  RequestOTPCodeByClientForm,
} from "@hubix/shared";
import { axiosApi } from "api/index";
import { AxiosResponse } from "axios";

export const loginVerificationApi = async (form: LoginVerificationForm) => {
  try {
    const response: AxiosResponse<ClientVerification> = await axiosApi.post(
      API_PATH.auth.login.frontend,
      form
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const RequestOPTCodeByClientIdApi = async (
  form: RequestOTPCodeByClientForm
) => {
  try {
    const response: AxiosResponse<ClientVerification> = await axiosApi.post(
      API_PATH.auth.optCodeByClientId.frontend,
      form
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signupApi = async (form: ClientSignUpform) => {
  try {
    const response: AxiosResponse<ClientVerification> = await axiosApi.post(
      API_PATH.auth.signUp.frontend,
      form
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

////----------------- Client ------------/////

export const optConfirmationApi = async (data: OTPConfirmation) => {
  try {
    const response: AxiosResponse<ClientVerification> = await axiosApi.post(
      API_PATH.auth.optConfirmation.frontend,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClientByEmailApi = async (data: OTPConfirmation) => {
  try {
    const response: AxiosResponse<Client> = await axiosApi.post(
      API_PATH.auth.optConfirmation.frontend
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const clientAuthByCookieApi = async () => {
  try {
    const response: AxiosResponse<Client> = await axiosApi.get(
      API_PATH.auth.authByCookie.frontend
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
