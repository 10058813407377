import { Colors } from "utils/constants";
import { DashboardItemCardColor } from "../constants";
import { BodyHeader } from "ui/typography";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddMeeting from "./addMeeting";
import { Client } from "@hubix/shared";
import { useAppSelector } from "app/hooks";
import MeetingItem from "./item";
import MeetingDetailPage from "./page";
import { isValidObjectId } from "./utils";

enum ItemSwitch {
  Meetings = "Meetings",
  New = "Book a meeting",

  MeetingDetail = "MeetingDetail",
  PreviousMeetings = "Previous Meetings",
  UpcomingMeeting = "Upcoming Meeting",
  TodayMeeting = "Today Meeting",
  MissedMeeting = " Missed Meeting",
  CancelledMeeting = "Cancelled Meeting",
}

type ItemEnumPath = { value: ItemSwitch; path: string };

const itemEnumPaths: ItemEnumPath[] = [
  { value: ItemSwitch.Meetings, path: "" },
  { value: ItemSwitch.New, path: "booking" },
  {
    value: ItemSwitch.PreviousMeetings,
    path: "previous",
  },
  {
    value: ItemSwitch.UpcomingMeeting,
    path: "upcoming",
  },

  {
    value: ItemSwitch.TodayMeeting,
    path: "today",
  },
  {
    value: ItemSwitch.MissedMeeting,
    path: "missed",
  },
  {
    value: ItemSwitch.CancelledMeeting,
    path: "cancelled",
  },
];

export const ItemSwitchArray = Object.values(ItemSwitch).filter(
  (item) => item !== ItemSwitch.MeetingDetail
);

const MeetingDashboard: React.FC<{ client: Client }> = ({ client }) => {
  const [selectItem, setSelectItem] = useState(ItemSwitch.Meetings);
  const bookMeetings = useAppSelector((state) => state.bookings);

  const { id } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const handleItemSwitch = (item: ItemSwitch) => {
    setSelectItem(item);
    const data = itemEnumPaths.find(
      (itemData: ItemEnumPath) => itemData.value === item
    );
    if (!data) return;
    navigate(`meeting/${data.path}`);
  };

  useEffect(() => {
    if (location.pathname.includes("booking")) {
      return setSelectItem(ItemSwitch.New);
    }

    if (location.pathname.includes("previous")) {
      return setSelectItem(ItemSwitch.PreviousMeetings);
    }
    if (location.pathname.endsWith("meeting/")) {
      return setSelectItem(ItemSwitch.Meetings);
    }

    if (id) {
      if (location.pathname.includes("meeting") && isValidObjectId(id)) {
        return setSelectItem(ItemSwitch.MeetingDetail);
      }
    }
  }, [location.pathname]);

  return (
    <div style={{}}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          overflowX: "auto",
          gap: "10px",
          padding: "10px",
        }}
      >
        {ItemSwitchArray.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: selectItem === item ? Colors.SelectedLight : "",
              padding: 15,
              paddingTop: 5,
            }}
          >
            <div
              onClick={() => {
                handleItemSwitch(item);
              }}
              className="buttonItem"
              style={{
                backgroundImage:
                  index + 2 < DashboardItemCardColor.length
                    ? DashboardItemCardColor[index]
                    : DashboardItemCardColor[0],
                whiteSpace: "nowrap",
                padding: "6px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              {item}
            </div>
          </div>
        ))}
      </div>

      <div
        style={{
          // backgroundColor: Colors.SelectedLight,
          width: "fit-content",
          margin: "auto",
        }}
      >
        {selectItem === ItemSwitch.New && (
          <div>
            <AddMeeting client={client} />
          </div>
        )}

        {selectItem === ItemSwitch.Meetings && bookMeetings && (
          <div>
            {bookMeetings?.map((meeting) => (
              <MeetingItem meeting={meeting} />
            ))}
          </div>
        )}

        {selectItem === ItemSwitch.MeetingDetail && (
          <div>
            <MeetingDetailPage />
          </div>
        )}
      </div>
    </div>
  );
};
export default MeetingDashboard;
