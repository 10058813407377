import { AddMeetingAttendeesForm, Booking, Client } from "@hubix/shared";
import { Btn } from "ui/button";
import { InvitePeopleForm, MeetingAttendee } from "./utils";
import { useState } from "react";
import { useAppDispatch } from "app/hooks";
import { invitePeopleToMeetingAsyncThunk } from "component/booking/store";

const MeetingAttendees: React.FC<{
  meeting: Booking;
  loginClient: Client | null;
}> = ({ meeting, loginClient }) => {
  const [showAddPeople, setShowAddPeople] = useState(false);
  const [form, setForm] = useState<AddMeetingAttendeesForm>({
    ...Object(),
    clientId: loginClient?._id as string,
    meetingId: meeting._id,
    inviterName: loginClient?.firstname + " " + loginClient?.lastname,
  });
  const dispatch = useAppDispatch();

  const sendInvitation = () => {
    dispatch(invitePeopleToMeetingAsyncThunk(form));
  };

  return (
    <div>
      {showAddPeople ? (
        <div>
          <InvitePeopleForm
            form={form}
            setForm={setForm}
            onSubmit={() => sendInvitation()}
          />
        </div>
      ) : (
        <div
          style={{
            gap: 10,
            display: "flex",
            flexDirection: "column",
            marginLeft: 20,
          }}
        >
          {meeting.attendee.map((attendee, index) => (
            <div key={index} style={{ width: "100%" }}>
              <MeetingAttendee people={attendee} />
            </div>
          ))}
        </div>
      )}

      <div style={{ margin: 20 }}>
        {loginClient?._id === meeting.clientId && (
          <Btn
            text={showAddPeople ? "Show people" : "Invite people"}
            onClick={() => setShowAddPeople(!showAddPeople)}
          />
        )}
      </div>
    </div>
  );
};

export default MeetingAttendees;
