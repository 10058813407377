export class CustomError implements ICustomError {
  message!: string;
  status!: number;
  additionalInfo!: any;
  action!: any; //
  type?: ErrorType;
  constructor(
    message: string,
    status: number = 500,
    additionalInfo: any = undefined,
    errorType: ErrorType = ErrorType.Unknown
  ) {
    this.message = message;
    this.status = status;
    this.additionalInfo = additionalInfo;
    this.type = errorType;
  }
}

export interface IResponseError {
  message: string;
  additionalInfo?: string;
}

export interface ICustomError {
  message?: string;
  status?: number;
  additionalInfo?: any;
  action?: ErrorTAction; //
  type?: ErrorType;
}

export enum ErrorType {
  UserExist = "UserExist",
  NoUserFound = "NoUserFound",
  SignUp = "SignUp",
  Unknown = "Unknown",
  LoginFailed = "loginFailed",
  OPTSendFailed = "OPTSendFailed",
  OTPExpired = "OTPExpired",
  InvalidOTPCode = "InvalidOTPCode",
  BookingFailed = "BookingFailed",
  CreateBookingFailed = "CreateBookingFailed",
  ConfirmVerificationFailed = "ConfirmVerificationFailed",
  RequestOPTCodeFailed = "RequestOPTCodeFailed",
  CookiesAuthFailed = "CookiesAuthFailed",
  InvitePeopleFailed = "InvitePeopleFailed",
  InvitePeopleDenied = "InvitePeopleDenied",
  InvitationAlreadySent = "InvitationAlreadySent",
  AddFeedbackFailed = "AddFeedbackFailed",
  AddFeedbackReplyFailed = "AddFeedbackReplyFailed",
}

export enum ErrorTAction {
  Other = "",
}
