import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import {
  errorSlice,
  timeSlotSlice,
  timezonesSlice,
} from "../utils/store/index";
import {
  bookingListSlices,
  bookingSlice,
} from "../component/booking/store/index";
import { dashboardSlice } from "component/dashboard/store";
import { authSlice, clientSlice } from "component/client/store";
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    timeZones: timezonesSlice.reducer,
    bookings: bookingListSlices.reducer,
    booking: bookingSlice.reducer,
    timeSlots: timeSlotSlice.reducer,
    dashboard: dashboardSlice.reducer,
    auth: authSlice.reducer,
    error: errorSlice.reducer,
    client: clientSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
