import { Link } from "@mui/material";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

export const CustomLink: React.FC<{ text: string; to: string }> = ({
  text,
  to,
}) => {
  return (
    <div>
      <RouterLink style={{}} to={to}>
        {text}
      </RouterLink>
    </div>
  );
};

export const CustomLinkWithQuestion: React.FC<{
  question: string;
  text: string;
  to: string;
}> = ({ text, question, to }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <i style={{ color: "gray", margin: 5 }}>{question} </i>
      <RouterLink style={{ margin: 5 }} to={to}>
        {text}
      </RouterLink>
    </div>
  );
};

export const CustomLinkWithBtn: React.FC<{
  text: string;
  onClick: () => void;
  isDisabled: boolean;
}> = ({ text, onClick, isDisabled = false }) => {
  return (
    <div>
      <Link
        component="button"
        disabled={isDisabled}
        variant="body2"
        onClick={onClick}
      >
        {text}
      </Link>
    </div>
  );
};
