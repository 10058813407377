//import { api_prefix } from "./index";

const feedback = "/feedback";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const FeedbackPath = {
  feedback,
};

const api_prefix = "api";

const handlePath = ({ BasePath, path }: { BasePath: string; path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${BasePath}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const FEEDBACK_API_PATH = {
  new: handlePath({
    BasePath: feedback,
    path: `new`,
  }),

  postReply: handlePath({
    BasePath: feedback,
    path: `post_reply`,
  }),
};
