import { Booking_API_PATH } from "./booking";
import { AUTH_API_PATH, CLIENT_API_PATH, ClientPath } from "./client";
import { dashboard_API_PATH } from "./dashboard";
import { FEEDBACK_API_PATH, FeedbackPath } from "./feedback";

let BASE_URI = "http://localhost:";
const booking = "/booking";
const schedule = "/schedule";
const timeSlot = "/time_slot";
const dashboard = "/dashboard";
const hubixlabUtils = "/hubixlab_utils";

let port = 8080;
if (process.env.NODE_ENV === "production") {
  // Your code for the production environment
  port = 8080; // this has to match the expose port in   server  Dockerfile
  BASE_URI = "https://server.hubixlab.com";
} else {
  BASE_URI = BASE_URI + port;
}
export const api_prefix = "api";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const PATHS = {
  ...ClientPath,
  ...FeedbackPath,
  booking,
  schedule,
  timeSlot,
  dashboard,
  hubixlab_utils: hubixlabUtils,
};

const handlePath = ({ BasePath, path }: { BasePath: string; path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${BasePath}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const API_PATH = {
  port,

  base_uri: `${BASE_URI}/`,

  updateTimeSlots: handlePath({
    BasePath: PATHS.schedule,
    path: "update_slots",
  }),
  updateTimezone: handlePath({
    BasePath: PATHS.schedule,
    path: "update_timezone",
  }),

  getTimezones: handlePath({
    BasePath: PATHS.hubixlab_utils,
    path: "timezones",
  }),

  createTimeSlots: handlePath({
    BasePath: PATHS.schedule,
    path: "create_default",
  }),

  getTimeSlots: handlePath({
    BasePath: PATHS.timeSlot,
    path: "all",
  }),

  timeSlotsByDay: handlePath({
    BasePath: PATHS.timeSlot,
    path: "day",
  }),

  client: CLIENT_API_PATH,
  dashboard: dashboard_API_PATH,
  booking: Booking_API_PATH,
  auth: AUTH_API_PATH,
  feedback: FEEDBACK_API_PATH,
};
