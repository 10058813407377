import { UUID } from "crypto";
import { ClientRole } from "../utils";

export interface Client {
  email: string;

  firstname: string;
  lastname: string;
  organizationName: string;
  _id: string;
  phoneNumber: string;
}

export enum OptVerificationType {
  Email = "Email",
  SMS = "SMS",
  Other = "Other",
}
export interface ClientVerification {
  isVerified: boolean;
  verifiedTime: Date;
  verifyLinkExpiresIn: Date;
  verificationErrorMessage: string | null;
  emailSentTime: Date;
  isEmailSent: boolean;
  isOptSent: boolean;
  isOptVerified: boolean;
  isUserExist: boolean;
  verifiedMeans: string;
  isVerificationRequired: boolean;
  verificationType: OptVerificationType;
  client?: Client;
}

export interface ClientSignUpform {
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  organizationName: string;
  clientRole: ClientRole;
}

export interface LoginVerificationForm {
  email: string;
  phoneNumber: string;
  verificationType: OptVerificationType;
}
