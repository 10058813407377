import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ITimezoneData } from "@hubix/shared";
import { axiosApi } from "api/index";

export const ColorBlue = "#7972E2";
export const NorwayIP = "81.26.56.43";
export const TopMargin = 65;

//"https://mycolor.space/gradient3?ori=to+right+bottom&hex=%238E6BD1&hex2=%237F82B7&hex3=%233956A8&submit=submit"

export interface ITimezone {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  seconds: number;
  dateTime: string;
  date: string;
  time: string;
  timeZone: string;
  dayOfWeek: string;
}

export interface ClientLocation {
  city: string;
  country: string;
  region: string;
  regionCode: string;
  regionName: string;
  timeZone: string;
  zip: string;
  lat: number;
  lon: number;
  isp: string;
  offset: number;
  continent: string;
}

//
export async function getTimeZones() {
  const test = [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Bamako",
    "Africa/Bangui",
  ];

  return test;
  const url = "https://timeapi.io/api/TimeZone/AvailableTimeZones";
  fetch(url)
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.error(error));

  const data: AxiosResponse<string[]> = await axiosApi.get(url);

  if (data.status === 200) {
    return data.data;
  } else {
    return [];
  }
  // return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export async function getDateTimeByZones(timeZone: string): Promise<ITimezone> {
  const url = `https://timeapi.io/api/Time/current/zone?timeZone=${timeZone}`;

  try {
    const data: AxiosResponse<ITimezone> = await axios.get(url);
    if (data.status === 200) {
      return data.data;
    } else {
      throw data.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getCurrentTimeZone(): Promise<ITimezoneData> {
  // const url = "http://ip-api.com/json/?fields=status,message,continent,country,countryCode,region,regionName,city,zip,lat,lon,timezone,offset";

  const url = "http://worldtimeapi.org/api/ip";

  const data: AxiosResponse<ITimezoneData> = await axios.get(url);

  if (data.status === 200) {
    return data.data;
  } else {
    throw data.data;
  }
}

export async function getCurrentTimeZoneDataByZone(
  timeZone: string
): Promise<ITimezoneData> {
  //const url = "http://ip-api.com/json/?fields=status,message,continent,country,countryCode,region,regionName,city,zip,lat,lon,timezone,offset";
  const url = "http://worldtimeapi.org/api/timezone/" + timeZone;

  const data: AxiosResponse<ITimezoneData> = await axios.get(url);
  if (data.status === 200) {
    return data.data;
  } else {
    throw data.data;
  }
}

export async function timeZoneData(): Promise<string[]> {
  // doc https://www.timeanddate.com/time/zones/
  await getCurrentTimeZone();
  const url = "http://worldtimeapi.org/api/timezone";

  const data: AxiosResponse<string[]> = await axios.get(url);
  if (data.status === 200) {
    return data.data;
  } else {
    throw data.data;
  }
}
