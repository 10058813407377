import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Button, MenuItem, TextField } from "@mui/material";
import { BookingForm, ClientRole, ClientRoleArray } from "@hubix/shared";
import { useAppSelector } from "app/hooks";
import PhoneNumberInput from "ui/phonenNumber";
import { InputField } from "ui/inputs";

const Booking: React.FC<{
  setForm: Dispatch<SetStateAction<BookingForm>>;
  form: BookingForm;

  onSubmit: () => void;
}> = ({ setForm, form, onSubmit }) => {
  const client = useAppSelector((state) => state.client);

  const handlePhoneNumber = (phoneNumber: string) => {
    setForm({ ...form, phoneNumber });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  //const theme = useTheme();
  //const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {}, [client]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <InputField
          value={form.agenda}
          label="Meeting Agenda"
          type="text"
          required={true}
          onChange={(agenda) => {
            setForm({ ...form, agenda });
          }}
        />

        <InputField
          value={form.description}
          label="Description"
          type="text"
          required={true}
          multiline={true}
          onChange={(description) => {
            setForm({ ...form, description });
          }}
        />
        {client ? (
          <div style={{ display: client ? "none" : "block" }}>
            <div style={{ display: "flex", gap: "10px" }}>
              <InputField
                value={form.firstname}
                label="First name"
                type="text"
                required={client === null}
                onChange={(firstname) => {
                  setForm({ ...form, firstname });
                }}
              />
              <InputField
                value={form.lastname}
                label="last name"
                type="text"
                required={client === null}
                onChange={(lastname) => {
                  setForm({ ...form, lastname });
                }}
              />
            </div>
            <InputField
              value={form.email}
              label="Email"
              type="email"
              required={true}
              onChange={(email) => {
                setForm({ ...form, email });
              }}
            />
          </div>
        ) : (
          ""
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <TextField
            id="outlined"
            select
            label="what is your Role ?"
            placeholder="your role"
            value={form.clientRole}
            fullWidth
            onChange={(role) => {
              setForm({ ...form, clientRole: role.target.value as ClientRole });
            }}
            //helperText="Please select your role"
          >
            {ClientRoleArray.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id=""
            label="What is organization name ?"
            required
            //helperText="add company name"
            fullWidth
            onChange={(organizationName) => {
              setForm({
                ...form,
                organizationName: organizationName.target.value,
              });
            }}
          />
        </div>
        <div style={{ marginTop: 8 }}>
          <PhoneNumberInput inputChange={handlePhoneNumber} />
        </div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          //className={styles.submitButton}
        >
          Get in touch
        </Button>
      </form>
    </div>
  );
};

export default Booking;
