import { BookType } from "../booking";
import { OptVerificationType } from "../client";

export enum UserType {
  JobSeeker = "JobSeeker",
  Client = "Client",
  Other = "Other",
}

export const COMPANY_NAME = "HubixLab";
export interface TimezoneItem {
  regionName: string;
  zone: string;
  name: string;
  displayName: string;
  utcOffset: string;
}
// meetingStats interface

export interface MeetingStats {
  id?: string;

  startTime?: number;
  endTime?: number;
  clientId?: string;
  bookingId?: string;
  date?: Date;
  isCancelled?: boolean;
  cancelledReason?: string;
  meetingEndTime?: Date;
  meetingSummary?: string;
  clientReview?: string;
  isSummaryAccepted?: boolean;
}

export interface MeetingSlot {
  _id: string;
  startTime: string;
  endTime: string;
  month: number;
  dayOfWeek: number;
  dateOfMonth: number;
  isTaken: boolean;
  hour: number;
  minute: number;
  timeZone: string;
}

export interface TimeSlotRequest {
  month: number;
  dateOfMonth: number;
}

export enum ClientRole {
  Developer = "Developer",
  Sales = "Sales",
  CEO = "CEO",
  IT_Manager = "IT Manager",
  Other = "Other",
}
export interface OTPConfirmation {
  type: OptVerificationType;
  otpValue: number;
  emailOrPhoneNumber: string;
  clientId: string;
}

export interface RequestOTPCodeByClientForm {
  type: OptVerificationType;
  clientId: string;
}
//  convert ClientRole to an array
export const ClientRoleArray: ClientRole[] = Object.values(ClientRole);

export const AdminTimezone = "";
export const SERVER_LOCATION_TIME_ZONE = "Europe/Oslo";
