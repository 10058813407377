import { Booking } from "@hubix/shared";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Btn } from "ui/button";

const MeetingItem: React.FC<{ meeting: Booking }> = ({ meeting }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Card
        variant="outlined"
        style={{
          minHeight: 260,

          borderRadius: 15,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <CardHeader
          title={meeting.agenda}
          subheader={`Date: ${new Date(
            meeting.meetingDate
          ).toLocaleDateString()}`}
        />
        <CardContent style={{ color: "white", textAlign: "center" }}>
          <Typography variant="h5">{meeting.description}</Typography>
        </CardContent>
        <Collapse />
        <CardActions style={{ alignSelf: "center" }}>
          <Btn
            text={"View meeting"}
            onClick={() => navigate(`meeting/${meeting._id}`)}
          />
        </CardActions>
      </Card>
    </div>
  );
};

export default MeetingItem;
