import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AxiosError } from "axios";
import { timeSlotByDaysApi, timezonesApi } from "../api";
import { UtilsEnum } from "../constants";
import {
  CustomError,
  MeetingSlot,
  TimeSlotRequest,
  TimezoneItem,
} from "@hubix/shared";

export const timeZoneAsyncThunk = createAsyncThunk(
  UtilsEnum.TIMEZONE_REDUX,
  async (_, thunkAPI) => {
    try {
      let response = await timezonesApi();
      if (response instanceof AxiosError) {
        return thunkAPI.rejectWithValue(response.response?.data);
      }
      return response;
    } catch (error) {
      let err = error as AxiosError;
      console.error("error: ", err.message);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const timezonesSlice = createSlice({
  name: UtilsEnum.TIMEZONE_REDUX,
  initialState: [] as TimezoneItem[],
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(timeZoneAsyncThunk.pending, (stat, action) => {
        // how do i call other action here
      })
      .addCase(timeZoneAsyncThunk.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(timeZoneAsyncThunk.rejected, (state, { payload }) => {
        if (payload instanceof CustomError) {
          console.log(payload);
          return;
        }
      });
  },
});

export default timezonesSlice.reducer;
// export const {  } = bookingSlices.actions;
// export const {  } = bookingSlice.actions;

//------ Time slots  ----- ////
export const timeSlotsByDayAsyncThunk = createAsyncThunk(
  UtilsEnum.TIMESLOTS_REDUX,

  async (param: TimeSlotRequest, thunkAPI) => {
    try {
      let response = await timeSlotByDaysApi(param);
      if (response instanceof AxiosError) {
        return thunkAPI.rejectWithValue(response.response?.data);
      }
      return response;
    } catch (error) {
      let err = error as AxiosError;
      console.error("error: ", err.message);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const timeSlotSlice = createSlice({
  name: UtilsEnum.UTILS_REDUX,
  initialState: [] as MeetingSlot[],
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(timeSlotsByDayAsyncThunk.pending, (stat, action) => {
        // how do i call other action here
      })
      .addCase(timeSlotsByDayAsyncThunk.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(timeSlotsByDayAsyncThunk.rejected, (state, { payload }) => {
        if (payload instanceof CustomError) {
          console.log(payload);
          return;
        }
      });
  },
});

export const errorSlice = createSlice({
  name: UtilsEnum.ERROR,
  initialState: null as CustomError | null,
  reducers: {
    setStateErrorToNull: (state) => {
      return null;
    },
    setErrorState: (state, action: PayloadAction<CustomError>) => {
      return action.payload;
    },
  },
});

export const { setStateErrorToNull, setErrorState } = errorSlice.actions;
