import { API_PATH, Dashboard, DashboardRequest } from "@hubix/shared";
import { axiosApi } from "../../../api";
import { AxiosResponse } from "axios";

export const getDashboardByIdApi = async (form: DashboardRequest) => {
  try {
    const response: AxiosResponse<Dashboard> = await axiosApi.post(
      API_PATH.dashboard.homeById.frontend,
      form
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
