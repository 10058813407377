import * as React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { add } from "date-fns";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styles from "../style.module.css";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";

import {
  Button,
  InputBase,
  List,
  ListItemButton,
  Popover,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import {
  ITimezoneData,
  TimeSlotRequest,
  MeetingSlot,
  TimezoneItem,
  BookingForm,
} from "@hubix/shared";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { timeSlotsByDayAsyncThunk, timeZoneAsyncThunk } from "utils/store";
import { timeZonesFilter } from "../utils";
import {
  convertTimeSlotToClientTime,
  convertToClientTime,
} from "utils/dateHandler";
import { ErrorMessage } from "ui/typography";
import { getCurrentTimeZone } from "utils/index";
import { Colors } from "utils/constants";

const TimeSlotForm: React.FC<{
  setForm: React.Dispatch<React.SetStateAction<BookingForm>>;
  form: BookingForm;
}> = ({ form, setForm }) => {
  const timezoneSelector = useAppSelector((state) => state.timeZones);
  const timeSlotSelector = useAppSelector((state) => state.timeSlots);

  const [date, setDate] = React.useState<Date>(new Date());
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [clientTimezone, setClientTimezone] = React.useState<ITimezoneData>();
  const [filteredTimezones, setFilteredTimezones] = React.useState<
    TimezoneItem[]
  >([]);
  const [error, setError] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [searchText, setSearchText] = React.useState(""); // State to store search text
  const [timeSlots, setTimeSlots] = React.useState(timeSlotSelector); // State to store time slots
  const [selectSlot, setSelectSlot] = React.useState<MeetingSlot | null>(null); // State to store time slots
  const [dateNextMonth, setDateNextMonth] = React.useState(
    add(new Date(), { months: 1 })
  );

  const showTimezone = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen((prev) => !prev);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    getCurrentTimeZone().then((timezone) => {
      setClientTimezone(timezone);
    });

    if (timezoneSelector.length < 1) {
      dispatch(timeZoneAsyncThunk());
    }

    if (timezoneSelector.length < 1) {
      //dispatch(timeSlotsByDayAsyncThunk());
    }
    setFilteredTimezones(timezoneSelector);
    setTimeSlots(timeSlotSelector);
  }, [timezoneSelector, timeSlotSelector]);

  const handleFilteredTimeZones = (value: string) => {
    setSearchText(value);

    setFilteredTimezones(timeZonesFilter(value, timezoneSelector));
  };

  const selectedDate = (date: Date | null, _: any) => {
    if (showError) {
      setShowError(false);
      setError("");
    }
    if (date) {
      setDate(date);
      const param: TimeSlotRequest = {
        month: date.getMonth(),
        dateOfMonth: date.getDate(),
      };
      dispatch(timeSlotsByDayAsyncThunk(param));
    }
  };

  const disableDateHandler = (data: Date) => {
    console.log();
    return false;
  };

  const confirmDate = () => {
    if (selectSlot) {
      setForm({
        ...form,
        timeSlot: selectSlot,
        clientTimezone: clientTimezone?.timezone as string,
      });
    } else {
      if (!showError) {
        setShowError(true);
        setError("Please select a time slot");
      }
    }
  };

  const handleSelectedTimeZone = (timezone: TimezoneItem) => {
    const tz: ITimezoneData = Object();
    tz.timezone = timezone.name;
    setClientTimezone(tz);
    setOpen(false);
    setAnchorEl(null);
  };

  const TimeSlotsItem = (slot: MeetingSlot) => {
    const clientTime = convertTimeSlotToClientTime(
      clientTimezone?.timezone as string,
      new Date(slot.startTime)
    );

    const hour = clientTime.hour();
    const minute = new Date(slot.startTime).getMinutes();
    return (
      <ListItemButton
        className={styles.timeListItem}
        disabled={slot.isTaken}
        selected={slot._id === selectSlot?._id}
        style={{
          backgroundColor:
            slot._id === selectSlot?._id
              ? Colors.Selected
              : Colors.SelectedLight,
        }}
        key={slot._id}
        onClick={() => {
          if (showError) {
            setShowError(false);
            setError("");
          }
          setSelectSlot(slot);
        }}
      >
        {hour}:{minute}
      </ListItemButton>
    );
  };
  return (
    <div>
      <div style={{ textAlign: "left", margin: 15, display: "flex" }}>
        <Typography variant="h6">Meeting Date : </Typography>
        {selectSlot !== null && (
          <div>
            <Typography variant="h6">
              {
                convertToClientTime(
                  clientTimezone?.timezone as string,
                  selectSlot.startTime
                ).date
              }{" "}
              :{" "}
              {
                convertToClientTime(
                  clientTimezone?.timezone as string,
                  selectSlot.startTime
                ).time
              }
            </Typography>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "30px",
          // alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateCalendar
              maxDate={dateNextMonth}
              minDate={new Date()}
              disablePast={true}
              views={["day"]}
              value={date}
              shouldDisableDate={disableDateHandler}
              onChange={selectedDate}
            />
          </LocalizationProvider>
          <div style={{ display: "flex", gap: 8, justifyContent: "center" }}>
            <ErrorMessage text={error} showError={showError} />
          </div>
          <div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={styles.submitButton}
              onClick={confirmDate}
            >
              Confirm date
            </Button>
          </div>
        </div>

        <div>
          <div>Choose meeting timezone </div>
          <div style={{ display: "flex" }} onClick={showTimezone}>
            {clientTimezone && (
              <div>
                {clientTimezone.abbreviation} UTC {clientTimezone.utc_offset}{" "}
                {clientTimezone.timezone} time
              </div>
            )}
            <div>
              <ArrowDropDownIcon sx={{ fontSize: 35, marginTop: "-5px" }} />
            </div>
          </div>

          <div style={{}}>
            <Popover
              id={"timezone_id"}
              open={open}
              anchorEl={anchorEl}
              // anchorOrigin={{
              //  // horizontal: "",
              //   vertical: "bottom",
              // }}
              onClose={showTimezone}
            >
              <div style={{ overflow: "auto", height: 400 }}>
                <InputBase
                  style={{ margin: 10 }}
                  placeholder="Search Time Zone"
                  value={searchText}
                  onChange={(e) => handleFilteredTimeZones(e.target.value)} // Update search text
                />
                {filteredTimezones.map((item, key) => (
                  <ListItemButton
                    onClick={() => handleSelectedTimeZone(item)}
                    key={key}
                  >
                    {item.displayName}
                  </ListItemButton>
                ))}
              </div>
            </Popover>
          </div>

          <div style={{ overflow: "auto", height: 400 }}>
            <List>
              {timeSlots.map((item, index) => {
                return TimeSlotsItem(item);
              })}
            </List>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeSlotForm;
