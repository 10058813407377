import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
} from "react-router-dom";

import Home from "../component/home";
import BookingPage from "../component/booking";
import Navbar from "../ui/navbar/Navbar";
import Dashboard from "component/dashboard";
import LoginForm from "component/client/form/login";
import SignupForm from "component/client/form/signup";
import OTPVerificationForm from "component/client/form/optVerification";
import { DashboardItem, DashboardItemList } from "@hubix/shared";

const Root = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <div style={{ zIndex: 2 }}>{<Navbar />}</div>
      <div style={{ flex: 1, zIndex: 0, height: "100%" }}>
        <Outlet />
      </div>
    </div>
  );
};

let router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      {/* other routes here */}
      <Route path="/" element={<Home />} />
      <Route path="/booking" element={<BookingPage />} />

      <Route path="/login" element={<LoginForm />} />
      <Route path="/signup" element={<SignupForm />} />
      <Route path="/verification/:id" element={<OTPVerificationForm />} />

      <Route path="/dashboard/*" element={<Dashboard />}>
        {DashboardItemList.map((menu, index) => {
          if (
            menu === DashboardItem.Meeting ||
            menu === DashboardItem.Project ||
            menu === DashboardItem.Calendar
          ) {
            return (
              <>
                <Route
                  key={Math.random() + index}
                  path={menu.toLocaleUpperCase()}
                  element={<Dashboard />}
                />
                <Route
                  key={Math.random() + "id" + "new"}
                  path={menu.toLocaleUpperCase() + "/booking"}
                  element={<Dashboard />}
                />
                <Route
                  key={index + "id"}
                  path={menu.toLocaleUpperCase() + "/:id"}
                  element={<Dashboard />}
                />
              </>
            );
          }
          return (
            <>
              <Route
                key={Math.random() + index}
                path={menu.toLocaleUpperCase()}
                element={<Dashboard />}
              />
              <Route
                key={index + "id"}
                path={menu.toLocaleUpperCase() + "/:id"}
                element={<Dashboard />}
              />
            </>
          );
        })}
      </Route>
    </Route>
  )
);

export default function RouterItem() {
  return <RouterProvider router={router} />;
}
