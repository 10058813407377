import React, { useEffect, useState } from "react";
import TimeSlotForm from "./form/timeSlot";
import Booking from "./form/booking";
import { Paper } from "@mui/material";
import { BookingForm, COMPANY_NAME, bookingForm } from "@hubix/shared";
import { useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { TopMargin } from "utils/index";

const BookingPage = () => {
  const [form, setForm] = useState<BookingForm>(bookingForm);

  const booking = useAppSelector((state) => state.booking);

  const navigate = useNavigate();

  useEffect(() => {
    if (booking) {
      navigate("/dashboard");
    }

    return () => {
      setForm(bookingForm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  return (
    <div
      style={{
        //   backgroundColor: "rgb(168, 136, 199)",
        height: "100%",
        paddingTop: TopMargin + 20,
      }}
    >
      <Paper
        style={{
          maxWidth: "600px",
          minHeight: "500px",
          margin: "auto",
        }}
      >
        <div
          style={{
            textAlign: "center",
            color: "rgb(149, 128, 168, 0.5)",
          }}
        >
          <div style={{ padding: 20 }}>
            <h2>Book appointment with {COMPANY_NAME}</h2>
          </div>
        </div>

        <div style={{ display: form.timeSlot === null ? "block" : "none" }}>
          <TimeSlotForm form={form} setForm={setForm} />
        </div>
        <div style={{ display: form.timeSlot === null ? "none" : "block" }}>
          <div></div>
          <div>
            <Booking setForm={setForm} form={form} />
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default BookingPage;
