import { Booking } from "../booking";
import { Client } from "../client";

export interface Dashboard {
  meetings: Booking[];
  client: Client;
}

export interface DashboardRequest {
  type: DashboardItem;
  email: string;
  clientId: string;
}

export enum DashboardItem {
  DashboardHome = "Dashboard",

  Meeting = "Meeting",
  Calendar = "Calendar",
  Project = "Project",
  Support = "Support",
  Account = "Account",
  Billing = "Billing",
}

export const DashboardItemList: DashboardItem[] = Object.keys(
  DashboardItem
).map((key) => DashboardItem[key as keyof typeof DashboardItem]);
