import {
  BookingForm,
  Client,
  ClientRole,
  ErrorType,
  MeetingSlot,
  TimeSlotRequest,
} from "@hubix/shared";
import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { newBookingAsyncThunk } from "component/booking/store";
import { add } from "date-fns";
import React, { useEffect, useState } from "react";
import Booking from "ui/booking";
import { Btn } from "ui/button";
import CustomDatepicker from "ui/datepicker";
import ErrorModal from "ui/error";
import SuccessPage from "ui/success";
import TimeSlot from "ui/timeSlot";
import { convertToClientTime } from "utils/dateHandler";
import { timeSlotsByDayAsyncThunk } from "utils/store";

enum BookingStages {
  DatePicker = "DatePicker",
  Form = "Form",
  Success = "Success",
}
const AddMeeting: React.FC<{ client: Client }> = ({ client }) => {
  const timeSlotSelector = useAppSelector((state) => state.timeSlots);
  const errorState = useAppSelector((state) => state.error);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [bookingStage, setBookingStage] = useState(BookingStages.DatePicker);

  const newBooking = useAppSelector((state) => state.booking);
  const [form, setForm] = useState<BookingForm>({
    ...Object(),
    meetingDate: null,
    clientId: client._id,
    email: client.email,
    clientRole: ClientRole.Other,
    phoneNumber: client.phoneNumber,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (errorState) {
      if (errorState?.type === ErrorType.CreateBookingFailed) {
        setError("Something went wrong in booking a new meeting, try again");
        setShowError(true);
      }
    }
  }, [errorState]);

  useEffect(() => {
    if (newBooking) {
      setBookingStage(BookingStages.Success);
    }
  }, [newBooking]);

  const selectedDate = (date: Date) => {
    if (date) {
      const param: TimeSlotRequest = {
        month: date.getMonth(),
        dateOfMonth: date.getDate(),
      };
      setForm({ ...form, meetingDate: date });
      dispatch(timeSlotsByDayAsyncThunk(param));
    }
  };

  const selectTimeSlot = (slot: MeetingSlot) => {
    setForm({ ...form, timeSlot: slot });
    setBookingStage(BookingStages.Form);
  };

  const onSubmit = () => {
    dispatch(newBookingAsyncThunk(form));
  };

  return (
    <div>
      {bookingStage === BookingStages.DatePicker && (
        <div style={{ display: "flex", backgroundColor: "white" }}>
          <div>
            <CustomDatepicker
              onSelect={selectedDate}
              selectedDate={form.meetingDate}
              maxDate={add(new Date(), { months: 1 })}
            />
          </div>
          <div>
            <TimeSlot
              setSelectedSlot={selectTimeSlot}
              timeSlots={timeSlotSelector}
              selectedSlot={form.timeSlot}
            />
          </div>
        </div>
      )}
      {bookingStage === BookingStages.Form && (
        <div>
          <div>
            <div style={{ marginBottom: 15, display: "flex", gap: 4 }}>
              <Btn
                text={"<"}
                onClick={() => setBookingStage(BookingStages.DatePicker)}
              />
              <Typography variant="h6">{"Meeting Date :"}</Typography>
              <div>
                <Typography variant="h6">
                  {
                    convertToClientTime(
                      form.clientTimezone,
                      form.timeSlot?.startTime as string
                    ).date
                  }{" "}
                  :{" "}
                  {
                    convertToClientTime(
                      form.clientTimezone,
                      form.timeSlot?.startTime as string
                    ).time
                  }
                </Typography>
              </div>
            </div>
          </div>
          <Booking setForm={setForm} form={form} onSubmit={onSubmit} />
        </div>
      )}

      {bookingStage === BookingStages.Success && (
        <div>
          <SuccessPage
            message={
              "Congratulation!,  your meeting with has been reserved successfully!"
            }
            subMessage="Looking forward to discussing with you."
            actionTitle="View meeting"
            actionClick={() => {}}
          />
        </div>
      )}
      <div>
        <ErrorModal
          open={showError}
          errorMessage={error}
          onclose={() => setShowError(false)}
          errorTitle={"Creating meeting Failed"}
        />
      </div>
    </div>
  );
};

export default AddMeeting;
