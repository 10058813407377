import { Dashboard, DashboardItem } from "@hubix/shared";
import React from "react";
import styles from "../style.module.css";

import ItemCard from "./card";
import { DashboardItemCardColor } from "../constants";

const DashboardHome: React.FC<Dashboard & { menuItem: DashboardItem }> = ({
  client,
  meetings,
  menuItem,
}) => {
  return (
    <div className={styles.grid_container}>
      <div>
        {meetings.length > 0 ? (
          <div style={{}}>
            <ItemCard
              item={menuItem}
              content={"Up coming meeting"}
              actionContent="View meetings"
              onClick={() => {}}
            />
          </div>
        ) : (
          <div style={{}}>
            <ItemCard
              item={menuItem}
              content={"There is no up coming meeting"}
              actionContent="Book a meeting"
              onClick={() => {}}
            />
          </div>
        )}
      </div>
      <div style={{}}>
        <ItemCard
          item={menuItem}
          content={"Every great Projects start somewhere"}
          actionContent="Let share here"
          backgroundColor={DashboardItemCardColor[1]}
          onClick={() => {}}
        />
      </div>
      <div style={{}}>
        <ItemCard
          item={menuItem}
          content={"Up coming items  Calendar"}
          actionContent="See Calendar"
          backgroundColor={DashboardItemCardColor[2]}
          onClick={() => {}}
        />
      </div>
    </div>
  );
};

export default DashboardHome;
