import {
  Client,
  Feedback,
  FeedbackReply,
  MeetingFeedbackReplyForm,
} from "@hubix/shared";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { addFeedbackReplyAsyncThunk } from "component/booking/store";
import { dateTimeElapsed } from "component/dashboard/utils";
import { FC, useState } from "react";
import { Colors } from "utils/constants";
import { ColorBlue } from "utils/index";

const FeedbackComment: React.FC<{
  feedback: Feedback;
  client: Client;
}> = ({ feedback, client }) => {
  const [replyForm, setReplyForm] = useState<MeetingFeedbackReplyForm>({
    firstname: client.firstname,
    lastname: client.lastname,
    clientId: client._id,
    meetingId: feedback.meetingId,
    content: "",
    feedbackId: feedback._id,
  });
  const [replyContents, setReplyContents] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  // const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   dispatch(addFeedbackReplyAsyncThunk(replyForm));
  // };
  const _handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Dispatch logic to add reply using replyContents[index]
    dispatch(addFeedbackReplyAsyncThunk(replyForm));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>, index: number) => {
    e.preventDefault();
    // Dispatch logic to add reply using replyContents[index]
  };
  const handleReplyContentChange = (index: number, content: string) => {
    const newContents = [...replyContents];
    newContents[index] = content;
    setReplyContents(newContents);
  };

  const FeedbackMessage: FC<{ feedback: Feedback | FeedbackReply }> = ({
    feedback,
  }) => {
    return (
      <Box
        style={{
          backgroundColor: Colors.SelectedLight,
          borderRadius: 6,
        }}
      >
        <div style={{ display: "flex", gap: 14 }}>
          <Typography
            color="textPrimary"
            style={{ fontSize: 14, fontWeight: "bold" }}
          >
            {feedback.firstname}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ fontSize: 14, fontWeight: "bold" }}
          >
            {dateTimeElapsed(feedback.createdAt)}
          </Typography>
        </div>

        <Typography variant="body2" color="textSecondary" gutterBottom>
          {feedback.content}
        </Typography>

        <Box sx={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "3",
            }}
          >
            <div>
              <div style={{ cursor: "pointer" }}>Reply</div>
            </div>
            <div>
              <Typography variant="body2" gutterBottom>
                Like
              </Typography>
            </div>
          </div>
          <div>
            {/* <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="add reply"
                id="outlined-start-adornment"
                size="small"
                required
                value={replyForm.content}
                onChange={(e) =>
                  setReplyForm({ ...replyForm, content: e.target.value })
                }
                sx={{ m: 1, paddingRight: 0 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment style={{}} position="end">
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: ColorBlue,
                          color: "white",
                          marginRight: "-15px",
                        }}
                      >
                        Send
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </form> */}
          </div>
        </Box>
      </Box>
    );
  };
  return (
    <Box>
      {/* <Avatar>{feedback.clientName?.charAt(0).toUpperCase()}</Avatar> */}

      <Box>
        <FeedbackMessage feedback={feedback} />
        <form onSubmit={_handleSubmit}>
          <TextField
            fullWidth
            label={"Reply to " + feedback.firstname}
            id="outlined-start-adornment"
            size="small"
            required
            value={replyForm.content}
            onChange={(e) =>
              setReplyForm({ ...replyForm, content: e.target.value })
            }
            sx={{ m: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment style={{}} position="end">
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: ColorBlue,
                      color: "white",
                      marginRight: "-15px",
                    }}
                  >
                    Send
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </form>
        <div style={{ marginLeft: 30 }}>
          {feedback.reply.map((item, index) => (
            <div
              key={index}
              style={{
                borderRadius: 5,
                margin: 10,
              }}
            >
              <FeedbackMessage feedback={item} />
            </div>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default FeedbackComment;
