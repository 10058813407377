import { Dashboard } from "@hubix/shared";
import { Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { upcomingMeeting } from "../utils";
import { Body } from "ui/typography";

export const Calendar: React.FC<Dashboard> = ({ meetings }) => {
  const meetingReminder = upcomingMeeting(meetings);
  return (
    <div>
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateCalendar
            //  maxDate={dateNextMonth}
            minDate={new Date()}
            disablePast={true}
            views={["day"]}
            //  value={date}
            // onChange={selectedDate}
          />
        </LocalizationProvider>
      </div>
      <div>
        <div>
          <div style={{ textAlign: "center" }}>
            <Typography variant="h4">Reminder</Typography>
            <div>
              <Body text={meetingReminder?.reminder} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
