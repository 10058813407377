import { API_PATH } from "@hubix/shared";
import axios, { AxiosInstance } from "axios";

//doc https://axios-http.com/docs/config_defaults
const axiosConfig = {
  baseURL: API_PATH.base_uri,
  withCredentials: true, // default
  headers: {
    "Content-Type": "application/json",
    // accept: "application/json",
  },
};

export const axiosApi: AxiosInstance = axios.create(axiosConfig);

//To test for error use this, this will case  error
//export const axiosApi = new Axios(axiosConfig)
