import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import React, { useEffect } from "react";
//import { useAppSelector } from "src/app/hooks";
import { Btn } from "../button";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import Products from "./products";

const Account: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
 const isUserLoggedIn  = false
  // useEffect(() => {
  //   if (userStatus.isLoggedIn) {
  //     setIsUserLoggedIn(userStatus.isLoggedIn);
  //   }
  // }, [userStatus]);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ right: 0 }}>
      <Box
        sx={{
          flexGrow: 0,
          display: { xs: "flex", md: "none" },
        }}
      >
        {isUserLoggedIn ? (
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt={"client"} />
          </IconButton>
        ) : (
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            onClick={handleOpenUserMenu}
            aria-label="menu"
            sx={{ mr: -2, color: "white" }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Menu
          sx={{ mt: "30px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <div style={{ marginRight: "20px", }}>
            {isUserLoggedIn ? (
              <MenuItem onClick={handleCloseUserMenu}>
                <Btn
                  text="logout"
                  onClick={handleCloseUserMenu}
                  type="primary"
                />
              </MenuItem>
            ) : (
              <>
                <Products />
                <MenuItem onClick={handleCloseUserMenu}>
                  <Btn
                    text="Login"
                    onClick={() => navigate("/login", { state: location })}
                    type="primary"
                  />
                </MenuItem>
              </>
            )}
          </div>
        </Menu>
      </Box>

      {/* //for large screen */}
      <Box
        sx={{
          flexGrow: 0,
          display: { xs: "none", md: "flex" },
        }}
      >
        {isUserLoggedIn ? (
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt={"client"} />
            </IconButton>
          </Tooltip>
        ) : (
          <div
            style={{
              flexGrow: 0,
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: "green",
              maxWidth: "100%",
            }}
          >
            <Btn
              text="Login "
              onClick={() => navigate("/login", { state: location })}
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Account;
