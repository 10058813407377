//import { api_prefix } from "./index";

const booking = "/booking";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const BookingPath = {
  booking,
};

const api_prefix = "api";

const handlePath = ({ BasePath, path }: { BasePath: string; path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${BasePath}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Booking_API_PATH = {
  addBooking: handlePath({ BasePath: booking, path: "new" }),
  allBookings: handlePath({ BasePath: booking, path: "all" }),
  invitePeople: handlePath({ BasePath: booking, path: "invite_people" }),
  bookingById: (id: string) => handlePath({ BasePath: booking, path: id }),
};
