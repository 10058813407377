import { Button, Divider, Paper } from "@mui/material";
import React, { useState, FormEvent, useEffect } from "react";
import styles from "./style.module.css";
import { signupThunk } from "../store";
import { useNavigate } from "react-router";
import { InputField } from "ui/inputs";
import { Body, ErrorMessage, Header } from "ui/typography";
import { useAppDispatch, useAppSelector } from "app/hooks";
import "intl-tel-input/build/css/intlTelInput.css";
import { ClientSignUpform, ErrorType } from "@hubix/shared";
import { HeightsAndWidth } from "utils/constants";
import { CustomLink, CustomLinkWithQuestion } from "ui/link";
import { setStateErrorToNull } from "utils/store";
import PhoneNumberInput from "ui/phonenNumber";

const SignupForm: React.FC = () => {
  const [screenHeight, setScreenHeight] = useState<number>(0);

  const [form, setForm] = useState<ClientSignUpform>(Object);

  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  const clientAuth = useAppSelector((state) => state.auth);
  const signupError = useAppSelector((state) => state.error);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const resetError = () => {
    if (!showError) return;
    dispatch(setStateErrorToNull());
    setShowError(false);
  };

  const handlePhoneNumber = (phoneNumber: string) => {
    setForm({ ...form, phoneNumber });
  };

  //Todo: do we need this  ?
  const updateScreenHeight = () => {
    let height = window.innerHeight - HeightsAndWidth.NavBarHeight;

    setScreenHeight(height);
  };

  useEffect(() => {
    let height = window.innerHeight - HeightsAndWidth.NavBarHeight;
    setScreenHeight(height);
    window.addEventListener("resize", updateScreenHeight);

    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  useEffect(() => {
    if (clientAuth?.client && clientAuth.isEmailSent) {
      return navigate(`/verification/${clientAuth.client?._id}`);
    }

    if (signupError) {
      if (signupError.type === ErrorType.UserExist) {
        setError("A user with this details already exist. Login stead");
        setShowError(true);
      }

      if (signupError.type === ErrorType.SignUp) {
        setError("Something went wrong in creating account, try again ");

        setShowError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientAuth, signupError]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    dispatch(signupThunk(form));
  };

  return (
    <div
      style={{
        height: "100%",
        margin: "auto",
        marginTop: "80px",
      }}
    >
      <Paper elevation={3} className={styles.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: 20,
              margin: "auto",
            }}
          >
            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              <Header text={"Welcome  to  HubixLab"} />
              {/* <BodyHeader text={"Sign up"} /> */}
            </div>

            <Divider style={{ height: 70, minHeight: 40 }} />
            <div style={{}}>
              <form onSubmit={handleSubmit} className={styles.formContainer}>
                <InputField
                  value={form.firstname}
                  label="First name"
                  type="text"
                  placeholder="enter your name"
                  onChange={(firstname) => {
                    resetError();
                    setForm({ ...form, firstname });
                  }}
                />

                <InputField
                  value={form.lastname}
                  label="Last name"
                  type="text"
                  placeholder="your last name"
                  onChange={(lastname) => {
                    resetError();
                    setForm({ ...form, lastname });
                  }}
                />

                <InputField
                  value={form.email}
                  label="Enter email"
                  type="email"
                  required={true}
                  placeholder="example@domain.com"
                  onChange={(email) => {
                    resetError();

                    setForm({ ...form, email });
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></div>

                <div>
                  <PhoneNumberInput
                    inputChange={handlePhoneNumber}
                    isRequired={true}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: 5,
                  }}
                >
                  <CustomLinkWithQuestion
                    question={"Already have  account ?"}
                    text={"login"}
                    to={"/login"}
                  />
                </div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={styles.submitButton}
                >
                  Sign up
                </Button>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "-15px",
                    gap: "i",
                  }}
                ></div>
              </form>
            </div>
            <div style={{ display: "flex", gap: 8 }}>
              <ErrorMessage text={error} showError={showError} />
              {showError && signupError?.type === ErrorType.UserExist && (
                <div style={{ marginTop: 5 }}>
                  <CustomLink text={"Login now"} to={"/login"} />
                </div>
              )}
            </div>
          </div>

          <div
            style={{ width: "100%", color: "white" }}
            className={styles.loginSide}
          >
            <div
              style={{
                margin: "auto",
                padding: "20px",
              }}
            >
              <Header text={"Sign up  to  starting exploring"} color="white" />
              <Divider style={{ margin: "20px" }} />
              <Body
                text={
                  "Let's start creating possibilities  together  and solving complex problem."
                }
                color="white"
              />
              <Body text={"See you inside "} color="white" />
              <Divider style={{ margin: "20px" }} />

              <Body
                text={
                  "An OPT code will be sent to your email to verify your account"
                }
                color="white"
              />

              <div style={{ display: "flex", gap: 10 }}>
                {/* <CheckCircleIcon style={{ color: "rgb(56, 211, 162)" }} />
                Phone (SMS) */}
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default SignupForm;
