import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { InputField } from "../../../ui/inputs";
import styles from "../style.module.css";
import {
  Button,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  BookingForm,
  ClientRole,
  ClientRoleArray,
  ErrorType,
} from "@hubix/shared";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { newBookingAsyncThunk } from "../store";
import PhoneNumberInput from "ui/phonenNumber";
import { ErrorMessage } from "ui/typography";
import { CustomLink } from "ui/link";
import { setStateErrorToNull } from "utils/store";
import { convertToClientTime } from "utils/dateHandler";

const Booking: React.FC<{
  setForm: Dispatch<SetStateAction<BookingForm>>;
  form: BookingForm;
}> = ({ setForm, form }) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const errorSate = useAppSelector((state) => state.error);

  const handlePhoneNumber = (phoneNumber: string) => {
    resetError();

    setForm({ ...form, phoneNumber });
  };

  const resetError = () => {
    if (!showError) return;
    dispatch(setStateErrorToNull());
    setShowError(false);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(newBookingAsyncThunk(form));
  };

  const theme = useTheme();
  //const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (errorSate) {
      if (errorSate.type === ErrorType.UserExist) {
        setShowError(true);
        setError("User with this detail exist");
      }
      if (errorSate.type === ErrorType.CreateBookingFailed) {
        setError("something went wrong creating a booking try again. ");
        setShowError(true);
      }
    } else {
      if (showError) {
        resetError();
      }
    }
  }, [errorSate]);

  return (
    <div>
      <div>
        <div style={{ textAlign: "left", margin: 15, display: "flex" }}>
          <Typography variant="h6">Meeting Date : </Typography>
          <div>
            <Typography variant="h6">
              {
                convertToClientTime(
                  form.clientTimezone,
                  form.timeSlot?.startTime as string
                ).date
              }{" "}
              :{" "}
              {
                convertToClientTime(
                  form.clientTimezone,
                  form.timeSlot?.startTime as string
                ).time
              }
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: 8, justifyContent: "center" }}>
        <ErrorMessage text={error} showError={showError} />
        {showError && errorSate?.type === ErrorType.UserExist && (
          <div style={{ marginTop: 5 }}>
            <CustomLink text={"Login now"} to={"/login"} />
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <InputField
          value={form.agenda}
          label="Meeting Agenda"
          type="text"
          required={true}
          onChange={(agenda) => {
            resetError();
            setForm({ ...form, agenda });
          }}
        />

        <InputField
          value={form.description}
          label="Description"
          type="text"
          required={true}
          multiline={true}
          onChange={(description) => {
            resetError();
            setForm({ ...form, description });
          }}
        />
        <div style={{ display: "flex", gap: "10px" }}>
          <InputField
            value={form.firstname}
            label="First name"
            type="text"
            required={true}
            onChange={(firstname) => {
              resetError();

              setForm({ ...form, firstname });
            }}
          />
          <InputField
            value={form.lastname}
            label="last name"
            type="text"
            required={true}
            onChange={(lastname) => {
              resetError();

              setForm({ ...form, lastname });
            }}
          />
        </div>
        <InputField
          value={form.email}
          label="Email"
          type="email"
          required={true}
          onChange={(email) => {
            resetError();

            setForm({ ...form, email });
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <TextField
            id="outlined"
            select
            label="what is your Role ?"
            placeholder="your role"
            fullWidth
            onChange={(role) => {
              resetError();

              setForm({ ...form, clientRole: role.target.value as ClientRole });
            }}
            //helperText="Please select your role"
          >
            {ClientRoleArray.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id=""
            label="What is organization name ?"
            required
            //helperText="add company name"
            fullWidth
            onChange={(organizationName) => {
              resetError();

              setForm({
                ...form,
                organizationName: organizationName.target.value,
              });
            }}
          />
        </div>
        <div style={{ marginTop: 8 }}>
          <PhoneNumberInput inputChange={handlePhoneNumber} />
        </div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={styles.submitButton}
        >
          Get in touch
        </Button>
      </form>
    </div>
  );
};

export default Booking;
