import React, { FC, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { Booking, Client } from "@hubix/shared";
import { useParams } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import IsLoading from "ui/isLoading";
import { Body } from "ui/typography";
import { Btn } from "ui/button";
import { handleTimeSlot } from "../utils";
import { Colors } from "utils/constants";
import { Item } from "./utils";
import MeetingAttendees from "./attendees";
import FeedbackPage from "./feedback";

enum MeetingItems {
  Detail = "Detail",
  People = "People",
  Documents = "Documents",
  Discussion = "Discussion",
}

const meetingItemsList: MeetingItems[] = Object.values(MeetingItems);

const MeetingDetailPage: FC = () => {
  const { id } = useParams();
  const meeting = useAppSelector((state) =>
    state.bookings.find((item) => item._id === id)
  );
  const client = useAppSelector((state) => state.client);

  const [selectedItem, setSelectedItem] = useState(MeetingItems.People);

  const isValidObjectId = (id: string) => {
    //expected id format
    const objectIdPattern = /^[0-9a-fA-F]{24}$/;
    return objectIdPattern.test(id);
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    if (!isValidObjectId(id)) {
      // the id is not a valid booking id
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {meeting ? (
        <Container
          style={{
            borderColor: "grey",
            borderWidth: 2,
            borderStyle: "solid",

            width: 600,
            maxWidth: 800,
          }}
        >
          <Typography
            style={{ textAlign: "center", color: "green", fontSize: 25 }}
          >
            Meeting details
          </Typography>
          <Paper elevation={0} style={{ padding: "20px" }}>
            <div style={{ display: "flex", justifyContent: "center", gap: 15 }}>
              {meetingItemsList.map((item) => (
                <Button
                  onClick={() => setSelectedItem(item)}
                  style={{
                    textTransform: "none",
                    backgroundColor:
                      selectedItem === item
                        ? Colors.Selected
                        : Colors.SelectedLight,
                  }}
                >
                  {item}
                </Button>
              ))}
            </div>
            {selectedItem === MeetingItems.Detail && (
              <div>
                <div>
                  <Item title={"Meeting agenda"} value={meeting?.agenda} />
                </div>

                <Divider style={{ margin: "10px 0" }} />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Item
                      title={"Date"}
                      value={handleTimeSlot(meeting?.meetTimeSlots).fullDate}
                    />
                  </div>

                  <div>
                    <Item
                      title={"Time"}
                      value={handleTimeSlot(meeting?.meetTimeSlots).time}
                    />
                  </div>
                </div>
                <Divider style={{ margin: "10px 0" }} />
                <div>
                  <Item
                    title={"Meeting description"}
                    value={meeting?.description}
                  />
                </div>
                <Divider style={{ margin: "10px 0" }} />

                <div>
                  <Item title={"Location"} value={meeting?.location} />
                </div>

                <Divider style={{ margin: "10px 0" }} />
                <div
                  style={{ display: "flex", justifyContent: "center", gap: 15 }}
                >
                  {meetingItemsList.map((item) => (
                    <Button
                      onClick={() => setSelectedItem(item)}
                      style={{
                        textTransform: "none",
                        backgroundColor:
                          selectedItem === item
                            ? Colors.Selected
                            : Colors.SelectedLight,
                      }}
                    >
                      {item}
                    </Button>
                  ))}
                </div>
              </div>
            )}
            {selectedItem === MeetingItems.People && (
              <div style={{}}>
                <Item title={"Attendees"} value={""} />

                <div
                  style={{ gap: 10, display: "flex", flexDirection: "column" }}
                >
                  <MeetingAttendees meeting={meeting} loginClient={client} />
                </div>
              </div>
            )}

            {selectedItem === MeetingItems.Discussion && (
              <div
                style={{ gap: 10, display: "flex", flexDirection: "column" }}
              >
                <FeedbackPage meeting={meeting} client={client as Client} />
              </div>
            )}
          </Paper>
        </Container>
      ) : (
        <div style={{ display: meeting ? "none" : "block" }}>
          <IsLoading />
        </div>
      )}
    </div>
  );
};

export default MeetingDetailPage;
