import {
  AppBar,
  Box,
  Container,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";

import React from "react";
import Account from "./account";
import Products from "./products";
import { HeightsAndWidth } from "utils/constants";
import { useLocation } from "react-router-dom";

const Navbar: React.FC = () => {
  const location = useLocation();
  return (
    <AppBar
      position="static"
      style={{
        display: location.pathname.includes("/dashboard") ? "none" : "block",
        backgroundColor: "transparent",
        height: HeightsAndWidth.NavBarHeight,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Link href="/">
            <div style={{ display: "flex", width: "200px", height: "2" }}>
              <Typography variant="h6">HubixLab</Typography>
            </div>
          </Link>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Products isFlex={true} />
          </Box>
          <div>
            <Account />
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
