import {
  API_PATH,
  MeetingSlot,
  TimeSlotRequest,
  TimezoneItem,
} from "@hubix/shared";
import { AxiosResponse } from "axios";
import { axiosApi } from "../api";

export const timezonesApi = async () => {
  try {
    const response: AxiosResponse<TimezoneItem[]> = await axiosApi.get(
      API_PATH.getTimezones.frontend
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

//export const timeSlotsApi = async (param: TimeSlotRequest) => {

export const timeSlotByDaysApi = async (param: TimeSlotRequest) => {
  try {
    const response: AxiosResponse<MeetingSlot[]> = await axiosApi.post(
      API_PATH.timeSlotsByDay.frontend,
      param
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
