import { ITimezoneData, MeetingSlot, TimezoneItem } from "@hubix/shared";
import { InputBase, List, ListItemButton, Popover } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { timeZonesFilter } from "component/booking/utils";
import React, { useEffect } from "react";
import { Colors } from "utils/constants";
import { convertTimeSlotToClientTime } from "utils/dateHandler";
import { getCurrentTimeZone } from "utils/index";
import { timeZoneAsyncThunk } from "utils/store";

const TimeSlot: React.FC<{
  setSelectedSlot: (timeSlot: MeetingSlot) => void;
  selectedSlot: MeetingSlot | null;
  timeSlots: MeetingSlot[];
}> = ({ timeSlots, selectedSlot, setSelectedSlot }) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const timezoneSelector = useAppSelector((state) => state.timeZones);
  const [clientTimezone, setClientTimezone] = React.useState<ITimezoneData>();
  const [filteredTimezones, setFilteredTimezones] = React.useState<
    TimezoneItem[]
  >([]);
  // const timeSlotSelector = useAppSelector((state) => state.timeSlots);
  // const [timeSlots, setTimeSlots] = React.useState(timeSlotSelector);

  const [searchText, setSearchText] = React.useState("");
  const showTimezone = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen((prev) => !prev);
  };
  const dispatch = useAppDispatch();

  const handleFilteredTimeZones = (value: string) => {
    setSearchText(value);

    setFilteredTimezones(timeZonesFilter(value, timezoneSelector));
  };

  const handleSelectedTimeZone = (timezone: TimezoneItem) => {
    const tz: ITimezoneData = Object();
    tz.timezone = timezone.name;

    setClientTimezone(tz);
    setOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    getCurrentTimeZone().then((timezone) => {
      setClientTimezone(timezone);
    });

    if (timezoneSelector.length < 1) {
      dispatch(timeZoneAsyncThunk());
    }

    if (timezoneSelector.length < 1) {
      //dispatch(timeSlotsByDayAsyncThunk());
    }
    setFilteredTimezones(timezoneSelector);
    // setTimeSlots(timeSlotSelector);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TimeSlotsItem = (slot: MeetingSlot) => {
    const clientTime = convertTimeSlotToClientTime(
      clientTimezone?.timezone as string,
      new Date(slot.startTime)
    );

    const hour = clientTime.hour();
    const minute = new Date(slot.startTime).getMinutes();
    return (
      <ListItemButton
        disabled={slot.isTaken}
        selected={slot._id === selectedSlot?._id}
        style={{
          backgroundColor:
            slot._id === selectedSlot?._id
              ? Colors.Selected
              : Colors.SelectedLight,
        }}
        key={slot._id}
        onClick={() => {
          setSelectedSlot(slot);
        }}
      >
        {hour}:{minute}
      </ListItemButton>
    );
  };

  return (
    <div>
      <div>
        <div>Choose meeting timezone </div>
        <div style={{ display: "flex" }} onClick={showTimezone}>
          {clientTimezone && (
            <div>
              {clientTimezone.abbreviation} UTC {clientTimezone.utc_offset}{" "}
              {clientTimezone.timezone} time
            </div>
          )}
          <div>
            <ArrowDropDownIcon sx={{ fontSize: 35, marginTop: "-5px" }} />
          </div>
        </div>

        <div style={{}}>
          <Popover
            id={"timezone_id"}
            open={open}
            anchorEl={anchorEl}
            // anchorOrigin={{
            //  // horizontal: "",
            //   vertical: "bottom",
            // }}
            onClose={showTimezone}
          >
            <div style={{ overflow: "auto", height: 400 }}>
              <InputBase
                style={{ margin: 10 }}
                placeholder="Search Time Zone"
                value={searchText}
                onChange={(e) => handleFilteredTimeZones(e.target.value)} // Update search text
              />
              {filteredTimezones.map((item, key) => (
                <ListItemButton
                  onClick={() => handleSelectedTimeZone(item)}
                  key={key}
                >
                  {item.displayName}
                </ListItemButton>
              ))}
            </div>
          </Popover>
        </div>

        <div style={{ overflow: "auto", height: 400 }}>
          <List>
            {timeSlots.map((item, index) => {
              return TimeSlotsItem(item);
            })}
          </List>
        </div>
      </div>
    </div>
  );
};

export default TimeSlot;
