import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Comment } from "./utils";
import {
  Booking,
  Client,
  Feedback,
  MeetingFeedbackForm,
  MeetingFeedbackReplyForm,
} from "@hubix/shared";
import { useAppDispatch } from "app/hooks";
import {
  addFeedbackAsyncThunk,
  addFeedbackReplyAsyncThunk,
} from "component/booking/store";
import FeedbackComment from "./feedback/comment";

const FeedbackPage: React.FC<{ meeting: Booking; client: Client }> = ({
  meeting,
  client,
}) => {
  const author = {
    firstname: client.firstname,
    lastname: client.lastname,
    clientId: meeting.clientId,
    meetingId: meeting._id,
  };
  const [form, setForm] = useState<MeetingFeedbackForm>({
    ...author,
    content: "",
  });

  const [replyForm, setReplyForm] = useState<MeetingFeedbackReplyForm>({
    ...Object(),
    content: "",
    feedbackId: "",
  });

  const dispatch = useAppDispatch();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(addFeedbackAsyncThunk(form));
  };

  const handleSubmitReply = () => {
    dispatch(addFeedbackReplyAsyncThunk(replyForm));
  };
  return (
    <>
      <Box>
        <form onSubmit={handleSubmit} style={{ marginTop: 15 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Add a discussion"
            multiline
            required
            rows={2}
            style={{ marginBottom: 6 }}
            value={form.content}
            onChange={(e) => setForm({ ...form, content: e.target.value })}
          />
          {/* <Btn text={"post feedback"} onClick={handleSubmit} /> */}
          <Button type="submit">Post comment</Button>
        </form>
      </Box>
      <div
        style={{
          overflowY: "auto",
          minHeight: "200px",
          maxHeight: "50vh",
        }}
      >
        <Box>
          {meeting.feedbacks.map((feedback, index) => (
            <div key={index} style={{ marginTop: 10 }}>
              <FeedbackComment feedback={feedback} client={client} />
            </div>
          ))}
        </Box>
      </div>
    </>
  );
};

export default FeedbackPage;
