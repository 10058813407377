import { MeetingSlot } from "@hubix/shared";
import { format } from "date-fns";

export const isValidObjectId = (id: string) => {
  const objectIdPattern = /^[0-9a-fA-F]{24}$/;
  return objectIdPattern.test(id);
};

export const dateHandler = (date: string | Date) => {
  return {
    fullDate: format(new Date(date), "EEEE, dd, MMMM, yyyy"),
    time: format(new Date(date), "HH: m"),
  };
};

export const handleTimeSlot = (timeslot: MeetingSlot[]) => {
  const slotCount = timeslot.length;
  if (slotCount < 1) {
    return {
      fullDate: "",
      startTime: "",
      endTime: "",
      time: "",
    };
  }
  return {
    fullDate: format(new Date(timeslot[0].startTime), "EEEE, dd, MMMM, yyyy"),
    startTime: format(new Date(timeslot[0].startTime), "HH: m"),
    endTime: format(new Date(timeslot[slotCount - 1].endTime), "HH: m"),
    time:
      format(new Date(timeslot[0].startTime), "HH:m") +
      " - " +
      format(new Date(timeslot[slotCount - 1].endTime), "HH:m"),
  };
};
