import { Booking } from "@hubix/shared";
import {
  differenceInDays,
  formatDistance,
  formatDistanceStrict,
  isPast,
} from "date-fns";

//Todo return all upcoming meeting instead
export const upcomingMeeting = (meeting: Booking[]) => {
  const data = {
    booking: Object() as Booking,
    reminder: "This is just a test meeting",
    //IsMeetingToday: false,
    isMeetingPast: false,
    remainingDay: 0,
  };

  if (meeting.length > 0) {
    data.booking = meeting[0];
    const todayDate = new Date();
    const meetingDate = new Date(meeting[0].meetingDate);

    if (isPast(meetingDate)) {
      data.isMeetingPast = true;
      data.booking = meeting[0];
      data.reminder = "No upcoming meeting";
      return data;
    }

    if (meetingDate > todayDate) {
      data.remainingDay = differenceInDays(meetingDate, todayDate);
      data.reminder = "Meeting in " + formatDistance(meetingDate, todayDate);
    }
  }

  return data;
};

export const dateTimeElapsed = (date: Date | string) => {
  console.log(date);
  const _date = new Date(date);
  if (!date) return "";
  var result = formatDistanceStrict(_date, new Date(), {
    addSuffix: true,
  });
  return result;
};
