import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

type ButtonType =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "intermediate";

interface ButtonProps {
  type?: ButtonType;
  onClick?: () => void;
  children?: React.ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  text: string;
  icon?: any;
}

export const Btn: React.FC<ButtonProps> = ({
  type,
  onClick,
  isLoading,
  isDisabled,
  text,
}) => {
  const handleClick = () => {
    if (onClick && !isLoading && !isDisabled) {
      onClick();
    }
  };

  return (
    <Button
      style={{ textTransform: "none" }}
      size="small"
      disableRipple
      variant="contained"
      //className={`button ${type}`}
      onClick={handleClick}
      disabled={isLoading || isDisabled}
    >
      {isLoading ? "Loading..." : text}
    </Button>
  );
};

export const BtnWithIcon: React.FC<
  ButtonProps & { iconPath: string; iconName: string }
> = ({ type, onClick, isLoading, isDisabled, text, iconPath, iconName }) => {
  const handleClick = () => {
    if (onClick && !isLoading && !isDisabled) {
      onClick();
    }
  };

  return (
    <Button
      variant="contained"
      className={`button ${type}`}
      onClick={handleClick}
      disabled={isLoading || isDisabled}
      fullWidth
    >
      <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
        <span style={{ display: "flex" }}>
          <img src={iconPath} alt={iconName} style={{ marginRight: "8px" }} />
        </span>
        <span style={{ textAlign: "center", width: "100%" }}>
          {isLoading ? "Loading..." : text}
        </span>
      </div>
    </Button>
  );
};

export const BtnWithNavigation: React.FC<{ text: string; path: string }> = ({
  text,
  path,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${path}`);
  };

  return (
    <Button variant="outlined" onClick={handleClick} color="success">
      {text}
    </Button>
  );
};
