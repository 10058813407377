import { TimezoneItem } from "@hubix/shared";


export const timeZonesFilter = (value: string, timeZone: TimezoneItem[]) => {

  const filtered = timeZone.filter((tz) => {
    return tz.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
  });

  const sortedFiltered = filtered.sort((a, b) => {
    const similarityA = a.name.toLowerCase().split(value).length - 1;
    const similarityB = b.name.toLowerCase().split(value).length - 1;

    return similarityB - similarityA;
  });

  return sortedFiltered;
};
