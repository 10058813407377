import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AxiosError } from "axios";
import { CustomError, Dashboard, DashboardRequest } from "@hubix/shared";
import { DashboardConstants } from "../constants";
import { setErrorState } from "utils/store";
import { getDashboardByIdApi } from "../api";
import { setBookings } from "component/booking/store";

export const getDashboardByClientIdThunk = createAsyncThunk(
  DashboardConstants.DashboardByEmail,
  async (form: DashboardRequest, thunkAPI) => {
    try {
      let response = await getDashboardByIdApi(form);
      //
      if (response.meetings) {
        thunkAPI.dispatch(setBookings(response.meetings));
      }

      return;
    } catch (error) {
      let err = error as AxiosError<CustomError>;
      if (err.response?.data) {
        thunkAPI.dispatch(setErrorState(err.response.data));
      }
    }
  }
);

export const dashboardSlice = createSlice({
  name: DashboardConstants.Dashboard,
  initialState: null as Dashboard | null,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getDashboardByClientIdThunk.pending, (stat, action) => {
        // how do i call other action here
      })
      .addCase(getDashboardByClientIdThunk.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(getDashboardByClientIdThunk.rejected, (state, { payload }) => {
        if (payload instanceof CustomError) {
          console.log(payload);
          return;
        }
      });
  },
});

// export const {  } = bookingSlices.actions;
// export const {  } = bookingSlice.actions;
