import { Typography } from "@mui/material";

export const Body: React.FC<{ text: string; color?: string }> = ({
  text,
  color = "black",
}) => {
  return (
    <Typography variant="body1" component="h3" color={color}>
      {text}
    </Typography>
  );
};

export const BodyHeader: React.FC<{ text: string; color?: string }> = ({
  text,
  color = "black",
}) => {
  return (
    <Typography
      color={color}
      variant="h3"
      style={{
        fontSize: "48px",
        fontWeight: 400,
        textTransform: "none",
        lineHeight: "56px",
        fontFamily: "Poppins",
      }}
    >
      {text}
    </Typography>
  );
};

export const Header: React.FC<{
  text: string;
  color?: string;
  textTransformation?: "none" | "capitalize" | "uppercase" | "lowercase";
}> = ({ text, color, textTransformation = "none" }) => {
  if (!color) {
    color = "black";
  }

  return (
    <Typography
      color={color}
      variant="h2"
      style={{
        fontSize: "40px",
        fontWeight: 600,
        textTransform: textTransformation,
        lineHeight: "50px",
        fontFamily: "Poppins",
      }}
    >
      {text}
    </Typography>
  );
};

export const ErrorMessage: React.FC<{ text: string; showError: boolean }> = ({
  text,
  showError = false,
}) => {
  return (
    <Typography
      color="red"
      component={"i"}
      style={{
        display: showError ? "block" : "none",
        fontSize: "17px",
        fontWeight: 30,
      }}
    >
      {text}
    </Typography>
  );
};
