import { Button, Divider, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import { useNavigate } from "react-router";
import { InputField } from "ui/inputs";
import { Body, Header } from "ui/typography";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ErrorType, OTPConfirmation, OptVerificationType } from "@hubix/shared";
import { handleNumberInput, hideSensitiveInfo } from "../utils";
import { confirmOTPThunk, requestOPTCodeByClientIdLoginThunk } from "../store";
import { CustomLinkWithBtn } from "ui/link";
import { useParams } from "react-router-dom";
import { setStateErrorToNull } from "utils/store";

const OTPVerificationForm: React.FC = () => {
  const [otpForm, setOtpForm] = useState<OTPConfirmation>(
    Object() && { otpValue: "" }
  );
  const [error, setError] = useState<string | null>(null);
  const [showResentOTP, setShowResentOTP] = useState(true);

  const clientAuth = useAppSelector((state) => state.auth);
  const authError = useAppSelector((state) => state.error);

  // const client = useAppSelector((state) => state.client);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const handleInputChange = (optInput: string) => {
    let opt = handleNumberInput(optInput);

    if (opt.length < 7) {
      setOtpForm({ ...otpForm, otpValue: opt as unknown as number });
      setError(null);
    } else {
      if (opt.length > 6) {
        setError(
          "Please enter only 6-digit OTP code but you are entering more"
        );
      } else {
        setError("Please enter a valid 6-digit OTP.");
      }
    }
  };

  useEffect(() => {
    dispatch(setStateErrorToNull());
  }, []);

  //todo: implement  resending of  otp
  const reSendOTP = () => {
    dispatch(
      requestOPTCodeByClientIdLoginThunk({
        type: OptVerificationType.Email,
        clientId: id as string,
      })
    );
  };

  useEffect(() => {
    if (authError) {
      if (authError.type === ErrorType.ConfirmVerificationFailed) {
        setError(
          "Something went wrong in verifying the Opt code, Please request a new code"
        );
      }

      if (authError.type === ErrorType.InvalidOTPCode) {
        setError(
          "The OTP code you entered is invalid, Please request a new code"
        );
      }

      if (authError.type === ErrorType.OTPExpired) {
        setError(
          "The OTP code you entered is invalid or expired, Please request a new code"
        );
      }

      if (
        authError.type === ErrorType.RequestOPTCodeFailed ||
        authError.type === ErrorType.NoUserFound
      ) {
        setShowResentOTP(false);
        setError("Something went wrong, Please request login");
      }
    }
  }, [authError]);

  useEffect(() => {
    console.log(clientAuth);
    if (clientAuth) {
      if (clientAuth.isVerified && clientAuth.isOptVerified) {
        navigate("/dashboard");
        return;
      }
      if (!clientAuth.isOptSent) {
        navigate("/login");
      }

      if (clientAuth.client) {
        setOtpForm({
          ...otpForm,
          type: clientAuth.verificationType,
          clientId: clientAuth.client?._id,
        });
      } else {
      }
    } else {
      if (id) {
        setOtpForm({
          ...otpForm,
          type: OptVerificationType.Email,
          clientId: id,
        });
      } else {
        setError("No details of sent opt  found , please request a new code");
      }
    }
  }, [clientAuth]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (otpForm.otpValue.toString().length !== 6) {
      setError("Please enter a valid 6-digit OTP.");
      return;
    }
    if (!otpForm.clientId) {
      setError("No details of sent opt  found , please request a new code");

      return;
    }
    dispatch(confirmOTPThunk(otpForm));
  };

  return (
    <div style={{ height: "100%", margin: "auto", marginTop: "80px" }}>
      <Paper elevation={3} className={styles.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: 20,
              height: "80%",
              margin: "auto",
            }}
          >
            <div
              style={{
                textAlign: "center",
                margin: "20px",
                marginBottom: "30px",
              }}
            >
              <div style={{ marginBottom: 40 }}>
                <Header text={"OPT Verification"} />
              </div>
              <Body
                text={
                  "Enter the OPT code sent to " +
                  hideSensitiveInfo(clientAuth?.verifiedMeans as string)
                }
              />
            </div>
            <form onSubmit={handleSubmit} className={styles.formContainer}>
              <InputField
                value={otpForm.otpValue}
                label="Enter OPT"
                type="text"
                required={true}
                placeholder="478338"
                helperText={error as string}
                error={error ? true : false}
                onChange={handleInputChange}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent:
                    id && showResentOTP ? "space-between" : "right",
                }}
              >
                {id && showResentOTP && (
                  <CustomLinkWithBtn
                    text={"Resend OPT"}
                    onClick={reSendOTP}
                    isDisabled={false}
                  />
                )}

                {clientAuth && !clientAuth.isVerificationRequired && (
                  <CustomLinkWithBtn
                    text={"Skip this step"}
                    onClick={() => navigate("/dashboard")}
                    isDisabled={false}
                  />
                )}
                {!clientAuth && (
                  <CustomLinkWithBtn
                    text={"login"}
                    onClick={() => navigate("/login")}
                    isDisabled={false}
                  />
                )}
              </div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={styles.submitButton}
              >
                Verify OTP
              </Button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "-15px",
                  gap: "i",
                }}
              ></div>
            </form>
          </div>

          <div
            style={{ width: "100%", color: "white" }}
            className={styles.loginSide}
          >
            <div
              style={{
                margin: "auto",
                width: "fit-content",
                height: "100%",
                padding: "20px",
              }}
            >
              <div style={{ textAlign: "center", marginBottom: 40 }}>
                <Header text={"Account verification"} color="white" />
              </div>
              <Body
                text={
                  "An OTP code has been sent to you, to verify your account please enter the code sent to you.. "
                }
                color="white"
              />

              <Divider style={{ margin: "20px" }} />

              <Body text={"See you in the other side!"} color="white" />
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default OTPVerificationForm;
