import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
dayjs.extend(utc);
dayjs.extend(timezone);

export function convertTimeSlotToClientTime(
  clientTimeZone: string,
  startSlot: Date
) {
  // const serverTime = dayjs(startSlot);
  const clientTime = dayjs.tz(startSlot, clientTimeZone);

  return clientTime;
}

export function convertToClientTime(clientTimeZone: string, startSlot: string) {
  // let serverTime = dayjs(startSlot);
  // console.log(new Date(startSlot).toDateString());
  // console.log(serverTime.hour());
  // serverTime = serverTime.tz(clientTimeZone);
  // console.log(serverTime.hour());

  const clientTime = dayjs(startSlot).tz(clientTimeZone);

  const date = clientTime.format("ddd MMM DD");
  const time = clientTime.format("HH:mm");
  return {
    time,
    date,
  };
}
