import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AxiosError } from "axios";
import {
  Client,
  ClientSignUpform,
  ClientVerification,
  CustomError,
  LoginVerificationForm,
  OTPConfirmation,
  RequestOTPCodeByClientForm,
} from "@hubix/shared";
import {
  clientAuthByCookieApi,
  getClientByEmailApi,
  loginVerificationApi,
  optConfirmationApi,
  RequestOPTCodeByClientIdApi,
  signupApi,
} from "../api";
import { ClientConstant } from "../constants";
import { setErrorState } from "utils/store";

export const LoginThunk = createAsyncThunk(
  ClientConstant.ClientOPTByEmail,
  async (form: LoginVerificationForm, thunkAPI) => {
    try {
      let response = await loginVerificationApi(form);

      return response;
    } catch (error) {
      let err = error as AxiosError<CustomError>;
      if (err.response?.data) {
        thunkAPI.dispatch(setErrorState(err.response.data));
      }
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

export const requestOPTCodeByClientIdLoginThunk = createAsyncThunk(
  ClientConstant.ClientOPTById,
  async (form: RequestOTPCodeByClientForm, thunkAPI) => {
    try {
      let response = await RequestOPTCodeByClientIdApi(form);

      return response;
    } catch (error) {
      let err = error as AxiosError<CustomError>;
      if (err.response?.data) {
        thunkAPI.dispatch(setErrorState(err.response.data));
      }
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

export const signupThunk = createAsyncThunk(
  ClientConstant.ClientSignup,
  async (form: ClientSignUpform, thunkAPI) => {
    try {
      let response = await signupApi(form);

      return response;
    } catch (error) {
      let err = error as AxiosError<CustomError>;
      if (err.response?.data) {
        thunkAPI.dispatch(setErrorState(err.response.data));
      }

      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

export const confirmOTPThunk = createAsyncThunk(
  ClientConstant.ConfirmOTP,
  async (data: OTPConfirmation, thunkAPI) => {
    try {
      let response = await optConfirmationApi(data);
      if (response instanceof AxiosError) {
        return thunkAPI.rejectWithValue(response.response?.data);
      }

      if (response.isVerified && response.client) {
        thunkAPI.dispatch(setClient(response.client));
        //clientSlice.actions.setClient(response.client);
      }

      return response;
    } catch (error) {
      let err = error as AxiosError<CustomError>;
      if (err.response?.data) {
        thunkAPI.dispatch(setErrorState(err.response.data));
      }

      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

export const authSlice = createSlice({
  name: ClientConstant.Auth,
  initialState: null as ClientVerification | null,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(LoginThunk.pending, (stat, action) => {
        // how do i call other action here
      })
      .addCase(LoginThunk.fulfilled, (state, { payload }) => {
        state = payload;
        console.log(state);
        return state;
      })
      .addCase(LoginThunk.rejected, (state, { payload }) => {
        if (payload instanceof CustomError) {
          console.log(payload);
          return;
        }
      })
      .addCase(signupThunk.pending, (stat, action) => {
        // how do i call other action here
      })
      .addCase(signupThunk.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(signupThunk.rejected, (state, { payload }) => {
        if (payload instanceof CustomError) {
          console.log(payload);
          return;
        }
      })

      .addCase(confirmOTPThunk.pending, (stat, action) => {
        // how do i call other action here
      })
      .addCase(confirmOTPThunk.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(confirmOTPThunk.rejected, (state, { payload }) => {
        if (payload instanceof CustomError) {
          console.log(payload);
          return;
        }
      })

      .addCase(requestOPTCodeByClientIdLoginThunk.pending, (stat, action) => {
        // how do i call other action here
      })
      .addCase(
        requestOPTCodeByClientIdLoginThunk.fulfilled,
        (state, { payload }) => {
          return payload;
        }
      )
      .addCase(
        requestOPTCodeByClientIdLoginThunk.rejected,
        (state, { payload }) => {
          if (payload instanceof CustomError) {
            console.log(payload);
            return;
          }
        }
      );
  },
});

////----------------- Client ------------/////

export const getClientByEmailThunk = createAsyncThunk(
  ClientConstant.ClientByEmail,
  async (data: OTPConfirmation, thunkAPI) => {
    try {
      let response = await getClientByEmailApi(data);
      if (response instanceof AxiosError) {
        let err = response as AxiosError<CustomError>;
        if (err.response?.data) {
          thunkAPI.dispatch(setErrorState(err.response.data));
        }

        return thunkAPI.rejectWithValue(err.response?.data);
      }

      return response;
    } catch (error) {
      let err = error as AxiosError<CustomError>;
      if (err.response?.data) {
        thunkAPI.dispatch(setErrorState(err.response.data));
      }

      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

export const clientAuthByCookieThunk = createAsyncThunk(
  ClientConstant.AuthClientByCookie,
  async (_, thunkAPI) => {
    try {
      let response = await clientAuthByCookieApi();
      if (response instanceof AxiosError) {
        let err = response as AxiosError<CustomError>;
        if (err.response?.data) {
          thunkAPI.dispatch(setErrorState(err.response.data));
        }

        return thunkAPI.rejectWithValue(err.response?.data);
      }

      return response;
    } catch (error) {
      let err = error as AxiosError<CustomError>;
      if (err.response?.data) {
        thunkAPI.dispatch(setErrorState(err.response.data));
      }

      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

export const clientSlice = createSlice({
  name: ClientConstant.Client,
  initialState: null as Client | null,
  reducers: {
    setClient: (state, action: PayloadAction<Client | null>) => {
      state = action.payload;
      return state;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getClientByEmailThunk.pending, (stat, action) => {
        // how do i call other action here
      })
      .addCase(getClientByEmailThunk.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(getClientByEmailThunk.rejected, (state, { payload }) => {
        if (payload instanceof CustomError) {
          console.log(payload);
          return;
        }
      })
      .addCase(clientAuthByCookieThunk.pending, (stat, action) => {
        // how do i call other action here
      })
      .addCase(clientAuthByCookieThunk.fulfilled, (state, { payload }) => {
        return payload;
      });
    // .addCase(clientAuthByCookieThunk.rejected, (state, { payload }) => {
    //   if (payload instanceof CustomError) {
    //     console.log(payload);
    //     return;
    //   }
    // });
  },
});

export const { setClient } = clientSlice.actions;
