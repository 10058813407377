import {
  API_PATH,
  AddMeetingAttendeesForm,
  Booking,
  BookingForm,
  MeetingFeedbackForm,
  MeetingFeedbackReplyForm,
} from "@hubix/shared";
import { axiosApi } from "../../../api";
import { AxiosResponse } from "axios";

export const newBookingApi = async (bookingForm: BookingForm) => {
  try {
    const response: AxiosResponse<Booking> = await axiosApi.post(
      API_PATH.client.meetingBooking.frontend,
      bookingForm
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const newBookingWithClientIdApi = async (bookingForm: BookingForm) => {
  try {
    const response: AxiosResponse<Booking> = await axiosApi.post(
      API_PATH.booking.addBooking.frontend,
      bookingForm
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllBookingApi = async () => {
  try {
    const response: AxiosResponse<Booking[]> = await axiosApi.get(
      API_PATH.booking.allBookings.frontend
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const invitePeopleToMeetingApi = async (
  form: AddMeetingAttendeesForm
) => {
  try {
    const response: AxiosResponse<Booking[]> = await axiosApi.post(
      API_PATH.booking.invitePeople.frontend,
      form
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addFeedbackApi = async (form: MeetingFeedbackForm) => {
  try {
    const response: AxiosResponse<Booking[]> = await axiosApi.post(
      API_PATH.feedback.new.frontend,
      form
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addFeedbackReplyApi = async (form: MeetingFeedbackReplyForm) => {
  try {
    const response: AxiosResponse<Booking[]> = await axiosApi.post(
      API_PATH.feedback.postReply.frontend,
      form
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
