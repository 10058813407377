import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { setStateErrorToNull } from "utils/store";
import { useAppDispatch } from "app/hooks";

const ErrorModal: React.FC<{
  open: boolean;
  errorMessage: string;
  errorTitle: string;
  actionButtonTitle?: string;
  onclose: () => void;
  actionClick?: () => void;
}> = ({
  open,
  errorMessage,
  errorTitle,
  actionButtonTitle,
  actionClick,
  onclose,
}) => {
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(setStateErrorToNull());
    onclose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ color: "red" }}>{errorTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{errorMessage}</DialogContentText>
        {actionClick && actionButtonTitle && (
          <Button onClick={actionClick} color="primary">
            {actionButtonTitle}
          </Button>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorModal;
