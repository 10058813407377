import React from "react";
import "./styles.css"; // Import a CSS file for styling
import Navbar from "../../ui/navbar/Navbar";

const HeroPage = () => {
  return (
    <div className="hero-container">
      <video autoPlay loop muted className="video-bg">
        <source src="video/frontpage.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-content">
        {/* <div>
          <Navbar />
        </div> */}
        <div className="hero-contentItem ">
          <div>
            <h1>
              Do you have an Idea, a Problem to solve, solution to provide ?
            </h1>
          </div>
          <div>
            <h1>Here at TechPool we are here for you!</h1>
            <button
              style={{
                background: "rgb(71, 33, 155)",
                color: "white",
                border: "1px solid rgb(0, 0, 0)",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={() => {
                window.location.href = "/contact";
              }}
            >
              Get in touch
            </button>
          </div>
        </div>
        {/* Add other content for your hero section */}
      </div>
    </div>
  );
};

export default HeroPage;
