import { ClientRole, MeetingSlot } from "../utils";

export interface ITimezoneData {
  abbreviation: string;
  client_ip: string;
  datetime: string;
  day_of_week: number;
  day_of_year: number;
  dst: boolean;
  dst_from: string;
  dst_offset: number;
  dst_until: string;
  timezone: string;
  utc_datetime: string;
  utc_offset: string;
  week_number: number;
}

export interface AddMeetingAttendeesForm {
  firstname: string;
  lastname: string;
  email: string;
  organization: string;
  inviterName: string;
  meetingId: string;
  role: ClientRole;

  clientId: string;
}
export interface BookingForm {
  agenda: string;
  description: string;
  firstname: string;
  lastname: string;
  clientId?: string;
  //Todo: make this  required
  meetingDate: Date;
  timeSlot: MeetingSlot | null;
  type: BookType;
  clientTimezone: string;
  email: string;
  phoneNumber: string;
  organizationName: string;
  clientRole: ClientRole;
  files?: FileDocument[];
}
export interface People {
  name: string;
  email: string;
  invitedByName: string;
  invitedById: string;
  organization: string;
  role: ClientRole;
  isHost: boolean;
  isAdmin: boolean;
  isInvitationDeclined: boolean;
}

export interface MeetingFeedbackForm {
  meetingId: string;
  firstname: string;
  lastname: string;
  clientId: string;
  content: string;
}
export interface MeetingFeedbackReplyForm extends MeetingFeedbackForm {
  feedbackId: string;
}
export interface Feedback {
  _id: string;
  lastname: string;
  firstname: string;
  meetingId: string;
  clientId: string;
  content: string;
  createdAt: Date;
  reply: FeedbackReply[];
}
export interface FeedbackReply extends Feedback {
  feedbackId: string;
}

export interface Booking {
  _id: string;
  agenda: string;
  description: string;
  type: BookType;
  clientId: string;
  meetTimeSlots: MeetingSlot[];
  meetingDate: Date;
  isCancelled: boolean;
  isDateChange: boolean;
  clientTimezone: string;
  updatedAt: string;
  email: string;
  phoneNumber: string;
  feedbacks: Feedback[];
  firstname: string;
  lastname: string;
  location: string;
  documents: string[];
  attendee: People[];
}

export enum BookType {
  Meeting = "meeting",
  Training = "training",
  Other = "other",
}

export interface FileDocument {
  //  files: File; // Array of File objects
  name: string; // The file name
  description: string;
  type: string;
}

export const bookingForm: BookingForm =
  new Object().constructor() as BookingForm;

//create default  object of BookingForm
bookingForm.timeSlot = null;
bookingForm.clientRole = ClientRole.Other;
