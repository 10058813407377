export enum UtilsEnum {
  UTILS_REDUX = "UTILS_REDUX",
  TIMEZONE_REDUX = "TIMEZONE_REDUX ",
  TIMESLOTS_REDUX = "TIMESLOTS_REDUX",
  ERROR = "ERROR",
}

export enum HeightsAndWidth {
  FooterHeight = 50,
  NavBarHeight = 60,
}

export enum Colors {
  Selected = "rgb(149, 128, 168,0.5)",
  SelectedLight = "rgb(149, 128, 100,0.1)",
  Button = "",
  BackgroundColor = "",
}
