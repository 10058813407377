import { MenuItem } from "@mui/material";
import { CustomLink } from "../link";

const Products: React.FC<{ isFlex?: boolean }> = ({ isFlex = false }) => {
  const style = {
    display: isFlex ? "flex" : "block",
    color: "white !important",
  };

  return (
    <div style={style}>
      <MenuItem>
        <CustomLink text={"Services"} to={"/service"} />
      </MenuItem>
      <MenuItem>
        <CustomLink text={"Booking"} to={"/booking"} />
      </MenuItem>
      <MenuItem>
        <CustomLink text={"Works"} to={"/works"} />
      </MenuItem>
      <MenuItem>
        <CustomLink text={"Blog"} to={"/blog"} />
      </MenuItem>
      <MenuItem>
        <CustomLink text={"About"} to={"/about"} />
      </MenuItem>
    </div>
  );
};

export default Products;
