import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AxiosError } from "axios";
import { BookingConstantEnum } from "../constants";
import {
  AddMeetingAttendeesForm,
  Booking,
  BookingForm,
  CustomError,
  MeetingFeedbackForm,
  MeetingFeedbackReplyForm,
} from "@hubix/shared";
import {
  addFeedbackApi,
  addFeedbackReplyApi,
  getAllBookingApi,
  invitePeopleToMeetingApi,
  newBookingApi,
  newBookingWithClientIdApi,
} from "../api";
import { setErrorState } from "utils/store";

// Todo: Remove these below  createAsyncThunk that as been commented

export const newBookingAsyncThunk = createAsyncThunk(
  BookingConstantEnum.ADD_NEW_BOOKING,
  async (bookingForm: BookingForm, thunkAPI) => {
    try {
      if (bookingForm.clientId) {
        return await newBookingWithClientIdApi(bookingForm);
      }
      return await newBookingApi(bookingForm);
    } catch (error) {
      let err = error as AxiosError<CustomError>;
      if (err.response?.data) {
        thunkAPI.dispatch(setErrorState(err.response.data));
      }

      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

export const getAllBookingAsyncThunk = createAsyncThunk(
  BookingConstantEnum.GET_ALL_BOOKINGS,
  async (_, thunkAPI) => {
    try {
      let response = await getAllBookingApi();
      if (response instanceof AxiosError) {
        return thunkAPI.rejectWithValue(response.response?.data);
      }

      return response;
    } catch (error) {
      let err = error as AxiosError<CustomError>;
      if (err.response?.data) {
        thunkAPI.dispatch(setErrorState(err.response.data));
      }

      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

export const invitePeopleToMeetingAsyncThunk = createAsyncThunk(
  BookingConstantEnum.INVITE_PEOPLE,
  async (form: AddMeetingAttendeesForm, thunkAPI) => {
    try {
      let response = await invitePeopleToMeetingApi(form);
      if (response instanceof AxiosError) {
        return thunkAPI.rejectWithValue(response.response?.data);
      }

      return response;
    } catch (error) {
      let err = error as AxiosError<CustomError>;
      if (err.response?.data) {
        thunkAPI.dispatch(setErrorState(err.response.data));
      }

      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

export const addFeedbackAsyncThunk = createAsyncThunk(
  BookingConstantEnum.ADD_FEEDBACK,
  async (form: MeetingFeedbackForm, thunkAPI) => {
    try {
      let response = await addFeedbackApi(form);
      if (response instanceof AxiosError) {
        return thunkAPI.rejectWithValue(response.response?.data);
      }

      return response;
    } catch (error) {
      let err = error as AxiosError<CustomError>;
      if (err.response?.data) {
        thunkAPI.dispatch(setErrorState(err.response.data));
      }

      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

export const addFeedbackReplyAsyncThunk = createAsyncThunk(
  BookingConstantEnum.ADD_FEEDBACK_REPLY,
  async (form: MeetingFeedbackReplyForm, thunkAPI) => {
    try {
      console.log(form);
      let response = await addFeedbackReplyApi(form);
      if (response instanceof AxiosError) {
        return thunkAPI.rejectWithValue(response.response?.data);
      }

      return response;
    } catch (error) {
      let err = error as AxiosError<CustomError>;
      if (err.response?.data) {
        thunkAPI.dispatch(setErrorState(err.response.data));
      }

      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

export const bookingSlice = createSlice({
  name: BookingConstantEnum.BOOKING,
  initialState: null as Booking | null,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(newBookingAsyncThunk.pending, (stat, action) => {
        // how do i call other action here
      })
      .addCase(newBookingAsyncThunk.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(newBookingAsyncThunk.rejected, (state, { payload }) => {
        if (payload instanceof CustomError) {
          console.log(payload);
          return;
        }
      });
  },
});

export const bookingListSlices = createSlice({
  name: BookingConstantEnum.BOOKING,
  initialState: [] as Booking[],
  reducers: {
    addBooking: (state, action: PayloadAction<Booking>) => {
      const item = state.filter(
        (meeting) => meeting._id === action.payload._id
      );
      if (item.length === 0) {
        state.push(action.payload);
      }
      return state;
    },
    setBookings: (state, action: PayloadAction<Booking[]>) => {
      return action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllBookingAsyncThunk.pending, (stat, action) => {
        // how do i call other action here
      })
      .addCase(getAllBookingAsyncThunk.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(getAllBookingAsyncThunk.rejected, (state, { payload }) => {
        if (payload instanceof CustomError) {
          console.log(payload);
          return;
        }
      })
      .addCase(
        invitePeopleToMeetingAsyncThunk.fulfilled,
        (state, { payload }) => {
          return payload;
        }
      )
      .addCase(
        invitePeopleToMeetingAsyncThunk.rejected,
        (state, { payload }) => {
          if (payload instanceof CustomError) {
            console.log(payload);
            return;
          }
        }
      )
      .addCase(addFeedbackAsyncThunk.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(addFeedbackAsyncThunk.rejected, (state, { payload }) => {
        if (payload instanceof CustomError) {
          console.log(payload);
          return;
        }
      })
      .addCase(addFeedbackReplyAsyncThunk.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(addFeedbackReplyAsyncThunk.rejected, (state, { payload }) => {
        if (payload instanceof CustomError) {
          console.log(payload);
          return;
        }
      });
  },
});

export const { addBooking, setBookings } = bookingListSlices.actions;
// export const {  } = bookingSlice.actions;
