import { Paper, Grid } from "@mui/material";
import HeroPage from "./heroPage";
import Footer from "ui/Footer";

const Home = () => {
  return (
    <Paper>
      <Grid>
        <HeroPage />
      </Grid>
      <div>
        <Footer />
      </div>
    </Paper>
  );
};

export default Home;
