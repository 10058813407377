import { DashboardItem } from "@hubix/shared";
import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import * as React from "react";
import { Btn } from "ui/button";

const defaultColor =
  "linear-gradient(to left top, #5b4f96, #574d97, #524b98, #4d499a, #48479b, #454fa2, #4156a8, #3e5eae, #4170b9, #4a82c2, #5893ca, #6aa4d1)";
const ItemCard: React.FC<{
  item: DashboardItem;
  content: string;
  actionContent: string;
  backgroundColor?: string;
  onClick: () => void;
}> = ({
  item,
  content,
  actionContent,
  onClick,
  backgroundColor = defaultColor,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "20px",
      }}
    >
      <Card
        variant="outlined"
        style={{
          minHeight: 260,

          borderRadius: 15,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          backgroundImage: backgroundColor,
        }}
      >
        <CardContent style={{ color: "white", textAlign: "center" }}>
          <Typography variant="h5">{content}</Typography>
        </CardContent>
        <CardActions style={{ alignSelf: "center" }}>
          <Btn text={actionContent} />
        </CardActions>
      </Card>
    </Box>
  );
};

export default ItemCard;
