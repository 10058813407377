/* eslint-disable react-hooks/exhaustive-deps */
import {
  AddMeetingAttendeesForm,
  ClientRole,
  ClientRoleArray,
  Feedback,
  FeedbackReply,
  MeetingFeedbackReplyForm,
  People,
} from "@hubix/shared";
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { dateTimeElapsed } from "component/dashboard/utils";
import { FC, useEffect } from "react";
import { InputField } from "ui/inputs";
import { Body } from "ui/typography";
import { Colors } from "utils/constants";
import { ColorBlue } from "utils/index";

const MeetingAttendee: React.FC<{ people: People }> = ({ people }) => {
  return (
    <div
      style={{
        backgroundColor: Colors.SelectedLight,
        borderRadius: 10,
        padding: 5,
      }}
    >
      <Typography
        style={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        {people.name}
      </Typography>
      <Typography
        style={{
          fontSize: 16,
          //fontWeight: "bold",
        }}
      >
        {people.email}
      </Typography>

      <Typography
        style={{
          fontSize: 16,
          //fontWeight: "bold",
        }}
      >
        {people.organization}
      </Typography>
    </div>
  );
};

const InvitePeopleForm: React.FC<{
  form: AddMeetingAttendeesForm;
  setForm: (form: AddMeetingAttendeesForm) => void;
  onSubmit: () => void;
}> = ({ form, onSubmit, setForm }) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <InputField
          value={form.firstname || ""}
          label="first name"
          type="text"
          required={true}
          onChange={(firstname) => {
            setForm({ ...form, firstname });
          }}
        />

        <InputField
          value={form.lastname || ""}
          label="last name"
          type="text"
          required={true}
          onChange={(lastname) => {
            setForm({ ...form, lastname });
          }}
        />

        <InputField
          value={form.email || ""}
          label="email"
          type="email"
          required={true}
          onChange={(email) => {
            setForm({ ...form, email });
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <TextField
            id="outlined"
            select
            label="Role ?"
            placeholder="your role"
            value={form.role || ClientRole.Other}
            fullWidth
            onChange={(role) => {
              setForm({ ...form, role: role.target.value as ClientRole });
            }}
            //helperText="Please select your role"
          >
            {ClientRoleArray.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id=""
            label="What is organization name ?"
            required
            //helperText="add company name"
            value={form.organization || ""}
            fullWidth
            onChange={(organization) => {
              setForm({
                ...form,
                organization: organization.target.value,
              });
            }}
          />
        </div>

        <div style={{ marginTop: 15 }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ textTransform: "none" }}
            //className={styles.submitButton}
          >
            Send Invitation
          </Button>
        </div>
      </form>
    </div>
  );
};

const Item: React.FC<{ title: string; value: string }> = ({ title, value }) => {
  return (
    <div>
      <Typography
        style={{
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Body text={value}></Body>
    </div>
  );
};

const Comment: React.FC<{
  feedback: Feedback;
  replyForm: MeetingFeedbackReplyForm;
  setReplyForm: (form: MeetingFeedbackReplyForm) => void;
  submitReply: () => void;
}> = ({ feedback, replyForm, setReplyForm, submitReply }) => {
  // const [showReplyBox, setshowReplyBox] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitReply();
  };

  useEffect(() => {
    setReplyForm({ ...replyForm, feedbackId: feedback._id });
  }, []);

  const FeedbackMessage: FC<{ feedback: Feedback | FeedbackReply }> = () => {
    return (
      <Box>
        <div style={{ display: "flex", gap: 14 }}>
          <Typography
            color="textPrimary"
            style={{ fontSize: 14, fontWeight: "bold" }}
          >
            {feedback.firstname}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ fontSize: 14, fontWeight: "bold" }}
          >
            {dateTimeElapsed(feedback.createdAt)}
          </Typography>
        </div>

        <Typography variant="body2" color="textSecondary" gutterBottom>
          {feedback.content}
        </Typography>

        <Box sx={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "3",
            }}
          >
            <div>
              <div style={{ cursor: "pointer" }}>Reply</div>
            </div>
            <div>
              <Typography variant="body2" gutterBottom>
                Like
              </Typography>
            </div>
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="add reply"
                id="outlined-start-adornment"
                size="small"
                required
                value={replyForm.content}
                onChange={(e) =>
                  setReplyForm({ ...replyForm, content: e.target.value })
                }
                sx={{ m: 1, paddingRight: 0 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment style={{}} position="end">
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: ColorBlue,
                          color: "white",
                          marginRight: "-15px",
                        }}
                      >
                        Send
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </div>
        </Box>
      </Box>
    );
  };
  return (
    <Box
      style={{
        backgroundColor: Colors.SelectedLight,
        borderRadius: 6,
        padding: 5,
      }}
    >
      {/* <Avatar>{feedback.clientName?.charAt(0).toUpperCase()}</Avatar> */}

      <Box>
        <FeedbackMessage feedback={feedback} />

        <div>
          {feedback.reply.map((item, index) => (
            <div key={index}>
              <FeedbackMessage feedback={item} />
            </div>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export { MeetingAttendee, Item, InvitePeopleForm, Comment };
