import React, { ChangeEvent, Component, useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface InputFieldProps {
  label: string;
  value: any;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  multiline?: boolean;
  placeholder?: string;
  type: "email" | "password" | "text" | "number" | "search" | "tel";
  onChange?: (value: string) => void;
}

export class InputField extends Component<InputFieldProps> {
  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(event.target.value);
    }
  };
  render() {
    const {
      label,
      value,
      type = "text",
      error = false,
      disabled = false,
      helperText,
      required = false,
      multiline = false,
      placeholder = "",
    } = this.props;

    return (
      <TextField
        label={label}
        type={type}
        value={value}
        disabled={disabled}
        onChange={this.handleChange}
        variant="outlined"
        color="success"
        multiline={multiline}
        fullWidth
        // defaultValue={defaultValue}
        placeholder={placeholder}
        style={{
          marginBottom: "4px",
          marginTop: "4px",
        }}
        required={required}
        autoComplete={type}
        inputProps={{ "aria-label": label }}
        error={error}
        helperText={helperText}
      />
    );
  }
}

interface PasswordInputFieldProps {
  label: string;
  value: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onChange?: (value: string) => void;
}

export const PasswordInputField: React.FC<PasswordInputFieldProps> = (
  props
) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = props;
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const { label, value, error = false, disabled = false, helperText } = props;

  return (
    <TextField
      label={label}
      value={value}
      disabled={disabled}
      onChange={handleChange}
      variant="outlined"
      margin="normal"
      fullWidth
      required
      autoComplete="new-password"
      inputProps={{ "aria-label": label }}
      error={error}
      helperText={helperText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleTogglePassword} edge="start">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export const CustomInputField: React.FC<PasswordInputFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = props;
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const { label, value, error = false, disabled = false, helperText } = props;

  return (
    <TextField
      label={label}
      type={showPassword ? "text" : "password"}
      value={value}
      disabled={disabled}
      onChange={handleChange}
      variant="outlined"
      margin="normal"
      fullWidth
      required
      autoComplete=""
      inputProps={{ "aria-label": label }}
      error={error}
      helperText={helperText}
    />
  );
};
