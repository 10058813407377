//import { api_prefix } from "./index";

const dashboard = "/dashboard";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const DashboardPath = {
  dashboard,
};

const api_prefix = "api";

const handlePath = ({ BasePath, path }: { BasePath: string; path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${BasePath}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const dashboard_API_PATH = {
  homeByEmail: handlePath({
    BasePath: DashboardPath.dashboard,
    path: `home/email`,
  }),

  homeById: handlePath({
    BasePath: DashboardPath.dashboard,
    path: `home/id`,
  }),
};
