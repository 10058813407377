import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const SuccessPage: React.FC<{
  actionTitle?: string;
  message: string;
  subMessage?: string;
  actionClick?: () => void;
}> = ({
  message = "Your action was successful. You're all set!",
  actionTitle,
  subMessage,
  actionClick,
}) => {
  const onclick = () => {
    if (actionTitle && actionClick) {
      actionClick();
    }
  };
  return (
    <Container maxWidth="sm">
      <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
        <CheckCircleOutlineIcon color="success" sx={{ fontSize: 80 }} />
      </Box>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        style={{ color: "green" }}
      >
        Success!
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        {message}
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        {subMessage}
      </Typography>
      {actionClick && (
        <div style={{ width: "fit-content", margin: "auto", marginTop: 30 }}>
          <Button
            variant="contained"
            color="success"
            onClick={onclick}
            style={{ color: "white" }}
          >
            {actionTitle}
          </Button>
        </div>
      )}
    </Container>
  );
};

export default SuccessPage;
