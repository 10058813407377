export function hideSensitiveInfo(emailOrPhoneNumber: string): string {
  if (!emailOrPhoneNumber) return "";

  if (emailOrPhoneNumber.includes("@")) {
    // It's an email, hide part of it
    const [username, domain] = emailOrPhoneNumber.split("@");
    const hiddenUsername = username.slice(0, 2) + "****"; // Replace with asterisks
    return hiddenUsername + "@" + domain;
  } else {
    // It's a phone number, hide part of it
    const visibleLength = 4; // Number of visible digits (e.g., last 4 digits)
    const hiddenLength = emailOrPhoneNumber.length - visibleLength;
    const hiddenPart = "*".repeat(hiddenLength);
    const visiblePart = emailOrPhoneNumber.slice(hiddenLength);
    return hiddenPart + visiblePart;
  }
}

export const handleNumberInput = (input: string) => {
  const numericValue = input.replace(/[^0-9]/g, "");
  return numericValue;
};
