import { DashboardItem, DashboardItemList } from "@hubix/shared";
import { List, ListItemButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SideMenu: React.FC<{
  menuItem: DashboardItem;
  setMenuItem: (item: DashboardItem) => void;
}> = ({ menuItem, setMenuItem }) => {
  const navigate = useNavigate();

  const handleSelectMenu = (item: DashboardItem) => {
    setMenuItem(item);
    if (item === DashboardItem.DashboardHome) {
      return navigate(`.`);
    }
    navigate(`${item.toLowerCase()}`);
  };

  const styles = (currenItem: DashboardItem) => {
    return {
      backgroundColor: currenItem === menuItem ? "white" : "",
      color: currenItem === menuItem ? "grey" : "white",
    };
  };
  return (
    <div
      style={{
        backgroundColor: "transparent",
        color: "white",
      }}
    >
      <List style={{ gap: 30, display: "grid" }}>
        {DashboardItemList.map((menu) => (
          <ListItemButton
            key={menu}
            style={styles(menu)}
            onClick={() => handleSelectMenu(menu)}
          >
            {menu}
          </ListItemButton>
        ))}
      </List>
    </div>
  );
};

export default SideMenu;
